@import "src/assets/styles/customs/variables";

.static-modal-wrapper {
  display: none;

  &.open {
    display: block;
  }

  .modal-content {
    .modal {
      display: flex;
      flex-direction: column;
      max-width: 560px;
      margin: 0 auto;
      background-color: $defaultBackground;
      border-radius: 20px;
      padding: 24px;
      height: calc(100vh - 200px);
      min-height: 357px;
      max-height: 800px;

      &.auto-height {
        height: auto;
        min-height: 0;

        @media (max-width: $mobileMaxWidth) {
          max-height: none;
        }

        & .modal-body {
          height: auto;
          max-height: calc(100vh - 180px);
        }
      }

      &.request-detail-modal {
        @media (max-width: $mobileMaxWidth) {
          top: 0;
          transform: initial;
          height: 100%;
          border-radius: 0;
          max-width: initial;
          max-height: initial;
          display: flex;
          flex-direction: column;
        }
      }

      &.confirmation-modal-wrapper {
        @media (max-width: $mobileMaxWidth) {
          top: 0;
          transform: initial;
          height: 100%;
          border-radius: 0;
          max-width: initial;
          max-height: initial;
          display: flex;
          flex-direction: column;
        }
      }

      @media (max-height: 356px) {
        & {
          top: initial;
          transform: initial;
        }
      }

      .modal-header {
        display: flex;
        align-items: center;

        & .modal-title {
          flex: 1 0;

          @media (max-width: $mobileMaxWidth) {
            & > .title2 {
              font-size: 20px;
            }
          }
        }

        &__close__btn {
          display: flex;
          cursor: pointer;
          color: $linkTextColor;
          padding: 0;
          outline: none;
          border: none;
          background-color: transparent;

          &:focus-visible {
            outline: 2px solid $point700;
          }
        }
      }

      .modal-body {
        margin: 32px 0;
        height: calc(100% - 132px);
        overflow: auto;

        @media (max-width: $mobileMaxWidth) {
          margin: 29px 0;
        }

        &.no-footer {
          height: calc(100% - 56px);
        }

        &::-webkit-scrollbar {
          width: 3px;
        }
      }

      .modal-footer {
        display: flex;
      }
    }
  }

  .overlay {
    z-index: 3;
  }
}

.tts-button-modal {
  vertical-align: sub;
}
