@import "src/assets/styles/customs/variables";

.textarea-wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid $gray8f;
  border-radius: 6px;
  resize: none;
  padding: 12px 16px;
  font-family: inherit;
}

textarea:focus-visible {
  outline: none !important;
  border: 2px solid $borderfocus !important;
}
textarea::-webkit-scrollbar {
  width: 3px !important;
  height: 4px !important;
}

/* Track */
textarea::-webkit-scrollbar-track {
  background: $white;
}

/* Handle */
textarea::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 5px;
  cursor: pointer;
}