@import "src/assets/styles/customs/variables";

.input-group-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;

  .login-label {
    font-family: $mainFont;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $blk;
    height: 20px;
  }

  .hc {
    color: $textColor;
  }

  input:focus {
    outline: none;
  }

  ::placeholder {
    color: $gray8f;
    font-size: 18px;

    @media (max-width: $mobileMaxWidth) {
      font-size: 16px;
    }
  }

  .margin-zero {
    margin: 0;
  }

  .invalid {
    border: solid 1px $negative;
  }

  .error-text {
    color: $negative;
    font-size: 14px;

    @media (max-width: $mobileMaxWidth) {
      font-size: 12px;
    }
  }

  input {
    font-family: $mainFont;
    border: 1px solid $gray55;
    border-radius: 26px;
    padding: 12px 16px;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;

    @media (max-width: $mobileMaxWidth) {
      font-size: 16px;
    }
  }
}
