@import "src/assets/styles/customs/variables";

.svg {
  display: flex;

  & > span {
    display: flex;
    width: 100%;
    height: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
