@import "src/assets/styles/customs/variables";

.top-banner {
  position: relative;
  width: 100%;
  // height: 1046px;
  height: 106px;
  background-color: $point200;
  z-index: -1;
}

main {
  margin-top: 82px;
  overflow: auto;
  height: calc(100vh - 82px);
  background-color: $defaultBackground;

  @media (max-width: $tabletPortraitMaxWidth) {
    margin-top: 56px;
    height: calc(100vh - 56px);
  }
  @media (max-width: $mobileUnsupportedWidth) {
    width: 360px;
    margin-top: 0;
    overflow-x: auto;
  }
}

.layout {
  min-height: 90vh;
}

.body {
  position: relative;
  @media (max-width: $mobileUnsupportedWidth) {
    overflow-x: auto;
    max-width: 1000px;
  }
}

.snap-scroll {
  scroll-snap-type: y mandatory;
}

html {
  background-color: $defaultBackground;
}

#error-status {
  color: $negative !important;
}
