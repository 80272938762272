@import "src/assets/styles/customs/variables";

.default {
  @media (max-width: $mobileMaxWidth) {
    padding: 0;
  }

  & > .container {
    @media (min-width: $tabletPortraitMinWidth) {
      padding: 0;
    }
  }

  &__title {
    font-size: 56px;
    margin-bottom: 24px;
    font-family: $sharpSansFont;
    line-height: 1.21;

    @media (max-width: $mobileMaxWidth) {
      overflow-x: auto;
      font-size: 24px;
      margin-bottom: 12px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__subtitle {
    font-family: $mainFont;
    font-weight: 500;
    margin-bottom: 12px;
    line-height: 1.33;

    & ~ .default__subtitle {
      margin-bottom: 40px;
    }
  }
  @media screen and (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
    &__title {
      font-size: 42px;
    }
    &__subtitle {
      font-size: 18px;
    }
  }
}
