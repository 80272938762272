@import "src/assets/styles/customs/variables";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-modal {
  @media (max-width: $mobileUnsupportedWidth) {
    .modal {
      width: 360px !important;
      margin: 0 !important;
    }
  }
  .hidden {
    display: none;
  }

  #resendTimerExpiredBtn:focus,
  #otp-expired-resend-btn:focus,
  .btn-link:focus {
    outline: 1px solid #000000;
  }

  .foccusable-div:has(:focus-visible) {
    outline: 2px solid $point700;
  }

  .otp-input-wrapper {
    justify-content: center;
    display: flex;
    gap: 16px;

    @media (max-width: $mobileMaxWidth) {
      gap: 4px;
      width: 100%;
    }

    .otp-input {
      font-family: $mainFont;
      width: 60px;
      height: 68px;
      border-radius: 10px;
      font-size: 36px;
      text-align: center;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      border: solid 1px $gray55;

      @media (max-width: $mobileMaxWidth) {
        width: 40px;
        border-radius: 12px;
        padding: 0;
        height: 48px;
        font-size: 28px;
      }

      @media (max-width: 280px) {
        width: 30px;
        height: 35px;
        font-size: 16px;
        border-radius: 8px;
      }

      &:disabled {
        background-color: $grayEe;
        border-color: $grayBb;
      }
    }

    .hc {
      background-color: transparent;
      color: $textColor;
    }
  }

  .hc-btn {
    border-color: $textColor;
    color: $textColor;
  }
}

.text-center {
  text-align: center;

  .b1 {
    @media (max-width: $mobileMaxWidth) {
      font-size: 14px;
    }
  }

  .txt-danger {
    color: $negative;
    display: inline;
  }

  .expired-otp-message {
    margin: 16px 0;
  }

  .max-attempt {
    color: $negative;
    margin-top: 20px;
  }

  .btn-link {
    font-family: $mainFont;
    cursor: pointer;
    margin: 0 0.2rem;
    padding: 0;
    background-color: transparent;
    color: $point700;
    outline: none;
    font-size: 16px;
    border-style: none;
    border-bottom: 1px solid $point700;
  }

  .hc {
    color: $linkTextColor;
    border-bottom: 1px solid $linkTextColor;
  }
}
