@import "src/assets/styles/customs/variables";

.consignment {
  overflow: hidden;
  max-width: 1392px;
  margin: 0 auto;
  padding: 80px 24px;
  &__date {
    font-weight: 500;
    margin-bottom: 24px;
  }

  .table-container {
    width: 100%;

    @media (max-width: $tabletPortraitMaxWidth) {
      overflow-x: auto;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    @media (max-width: $tabletPortraitMaxWidth) {
      width: 1392px;
    }
  }

  tr {
    &:first-child {
      background-color: $consignmentTable;
    }
  }

  td {
    padding: 8px;
    width: 250px;
    border-bottom: 1px solid $consignmentTable;

    a {
      color: $linkTextColor2;
      text-decoration: underline;
    }
  }
}
