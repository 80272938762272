@import "src/assets/styles/customs/variables";

.errorDetail {
  height: auto;
  width: 100%;
  padding: 120px 0;

  [data-theme="hc"] & {
    &__container {
      background-color: $gray31;
      height: 90vh;
    }
  }

  &__content {
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;

    &__icon {
      margin: 0 auto;
      height: 320px !important;
      width: 320px !important;
    }

    &__description {
      margin: 32px auto 0px;
      overflow-wrap: break-word;

      &__title {
        [data-theme="hc"] & {
          color: #00dfe6; // for IE
        }
      }

      &__caption {
        overflow-wrap: break-word;
        margin-top: 16px;
        color: $gray75;

        [data-theme="hc"] & {
          color: #00dfe6; // for IE
          color: $textColor;
        }
      }
    }

    &__buttons {
      margin-top: 24px;

      // 'var' does not work on IE
      // styles have to be redundant to work on IE
      button {
        background-color: #000;
        color: $white;

        &:hover {
          background-color: $gray75;
        }

        [data-theme="hc"] & {
          background-color: #fff01f;
          color: #000;

          &:hover {
            background-color: #00dfe6;
          }
        }

        @media (min-width: $tabletPortraitMinWidth) {
          &:last-child {
            margin: 0 0 0 20px;
          }
        }

        @media (max-width: $mobileMaxWidth) {
          align-self: center;

          &:last-child {
            margin: 20px 0 0 0;
          }
        }
      }

      @media (max-width: $mobileMaxWidth) {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media (max-width: $mobileMaxWidth) {
  .errorDetail {
    height: auto;
    width: 100%;
    padding: 120px 0;

    &__content {
      margin: 0 auto;
      text-align: center;
      display: flex;
      flex-direction: column;

      &__icon {
        margin: 0 auto;
        height: 160px !important;
        width: 160px !important;
      }

      &__description {
        margin: 32px auto 0px;
        overflow-wrap: break-word;

        &__title {
          font-size: 18px;
        }

        &__caption {
          overflow-wrap: break-word;
          margin-top: 16px;
          color: $gray75;
          font-size: 16px;
        }
      }
    }
  }
}
