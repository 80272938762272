@import "src/assets/styles/customs/variables";

.switch-content {
  position: relative;
  display: inline-block;
  padding: 0;
  border: none;
  background-color: transparent;

  &:focus-visible {
    outline: 2px solid $point700;
  }

  &__track {
    width: 36px;
    height: 16px;
    border: 1px solid $borderSwitch;
    border-radius: 8px;
    margin: 4px 0;
  }

  &__thumb {
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $background3;
    border: 1px solid $borderSwitch;
  }

  &.on {
    & .switch-content__track {
      background-color: $switchOnBg;
      border: none;
    }

    & .switch-content__thumb {
      background-color: $switchOnThumbBg;
      border: 1px solid $borderSwitch;
      right: 0;
    }
  }
}
