@import "src/assets/styles/customs/variables";

.usage-guide-modal {
  @media (max-width: $mobileMaxWidth) {
    .modal-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 12;
    }
  }

  .modal {
    max-width: 640px !important;
    @media (max-width: $mobileUnsupportedWidth) {
      margin: 0 !important;
      width: 360px;
    }
    @media (max-width: $mobileMaxWidth) {
      top: initial !important;
      transform: initial !important;
      border-radius: 0 !important;
      max-height: initial !important;
      min-width: 100vw;
    }
  }

  .modal-body {
    overflow-x: hidden !important;

    &:focus-visible {
      outline: none;
    }
  }

  .button-container {
    flex: 1;
    padding: 0;

    & > button {
      width: 100%;
    }

    &:has(:focus-visible) {
      outline: 2px solid $point700;
    }
  }

  &__message {
    padding: 16px;
    border: 1px solid $line;
    border-radius: 12px;
    margin-bottom: 32px;

    p {
      text-align: center;
      margin: 0;
    }
  }

  &__heading {
    margin-bottom: 12px;
  }

  &__paragraph {
    margin: 0 0 32px;
  }

  &__table {
    background-color: $background2;
    padding: 24px;
    border-radius: 12px;
    margin-top: 16px;

    @media (max-width: $mobileMaxWidth) {
      height: calc(100% - 16px);
    }

    &__number {
      font-family: $sharpSansFont;
      padding: 0 3px;

      @media (max-width: $mobileMaxWidth) {
        font-weight: bold;
      }
    }

    .line {
      margin: 22px 0;
      background-color: $line;
      height: 1px;
      background-color: $line;
    }

    &__row {
      align-items: center;

      @media (max-height: $mobileMaxWidth) {
        flex-direction: column;
      }

      .col {
        @media (max-width: $mobileMaxWidth) {
          flex: initial;
          text-align: center;
        }
      }

      .svg {
        margin: 0 auto;
      }

      &__left-col {
        flex: 0 !important;

        @media (max-width: $mobileMaxWidth) {
          &.icon {
            margin: 16px 0;
          }
        }
      }

      &__text-col {
        margin-left: 8px;
        flex: 1 !important;

        &__bullets {
          font-size: 14px !important;
          text-align: left;
          &.first {
            margin-top: 8px;
          }

          [dir="rtl"] & {
            text-align: right;
          }
        }

        @media (max-width: $mobileMaxWidth) {
          text-align: center;
        }
      }
    }

    &__title {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .slider-container {
    margin-bottom: 41px;

    & .slider-control-centerright,
    & .slider-control-centerleft {
      display: none;
    }

    .paging-dots {
      top: 30px !important;
      border: 1px solid rgba($blk, 0.1);
      border-radius: 16px;
      padding: 0 6px !important;

      .paging-item {
        button {
          padding: 0 6px;
          opacity: 1 !important;

          svg {
            fill: $gray8f;
          }
        }

        &.active {
          svg {
            fill: $blk;
          }
        }
      }

      .paging-dot {
        width: 8px;
        height: 8px;
      }
    }
  }

  .usage-guide-modal__rtl {
    .usage-guide-modal__table__row__text-col__bullets {
      text-align: right;
    }
  }

  .slider-control-bottomcenter {
    position: absolute;
    bottom: 0;
    @media (max-width: $mobileUnsupportedWidth) {
      position: relative;
      bottom: -10px;
    }
  }

  .modal-body {
    margin-bottom: 64px !important;
  }
}
