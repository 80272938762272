/* SamsungOne regular */
@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: normal;
  src: url(SamsungOne/SamsungOne-400_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-400_v1.1_20161104.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneKorean';
  font-style: normal;
  font-weight: normal;
  src: url(SamsungOneKorean-400_Light.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneHebrew';
  font-style: normal;
  font-weight: normal;
  src: url(SamsungOneHebrew-450.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneArabic';
  font-style: normal;
  font-weight: normal;
  src: url(SamsungOneArabic-450_v1.1.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneThai';
  font-style: normal;
  font-weight: normal;
  src: url(SamsungOneThai/SamsungOneThai-450_v2.0_20181214.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneLatin';
  font-style: normal;
  font-weight: normal;
  src: url(SamsungOneLatin/SamsungOneLatin-400_v2.0_20200508.woff) format("woff")
}

/* Other FontWeights */

/* SamsungOne */


@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: 300;
  src: url(SamsungOne/SamsungOne-400_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-400_v1.1_20161104.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: 400;
  src: url(SamsungOne/SamsungOne-400_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-400_v1.1_20161104.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: 500;
  src: url(SamsungOne/SamsungOne-400_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-400_v1.1_20161104.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: 600;
  src: url(SamsungOne/SamsungOne-400_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-400_v1.1_20161104.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: bold;
  src: url(SamsungOne/SamsungOne-700_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-700_v1.1_20161104.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: 700;
  src: url(SamsungOne/SamsungOne-700_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-700_v1.1_20161104.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOne';
  font-style: normal;
  font-weight: 800;
  src: url(SamsungOne/SamsungOne-700_v1.1_20161104.eot) format("eot"), url(SamsungOne/SamsungOne-700_v1.1_20161104.woff) format("woff")
}

/* SamsungOneKorean */

@font-face {
  font-family: 'SamsungOneKorean';
  font-style: normal;
  font-weight: 400;
  src: url(SamsungOneKorean-400_Light.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneKorean';
  font-style: normal;
  font-weight: bold;
  src: url(SamsungOneKorean-700_Light.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneKorean';
  font-style: normal;
  font-weight: 700;
  src: url(SamsungOneKorean-700_Light.woff) format("woff")
}

/* SamsungOneHebrew */

@font-face {
  font-family: 'SamsungOneHebrew';
  font-style: normal;
  font-weight: 400;
  src: url(SamsungOneHebrew-450.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneHebrew';
  font-style: normal;
  font-weight: bold;
  src: url(SamsungOneHebrew-700.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneHebrew';
  font-style: normal;
  font-weight: 700;
  src: url(SamsungOneHebrew-700.woff) format("woff")
}

/* SamsungOneArabic */

@font-face {
  font-family: 'SamsungOneArabic';
  font-style: normal;
  font-weight: 400;
  src: url(SamsungOneArabic-450_v1.1.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneArabic';
  font-style: normal;
  font-weight: bold;
  src: url(SamsungOneArabic-700_v1.1.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneArabic';
  font-style: normal;
  font-weight: 700;
  src: url(SamsungOneArabic-700_v1.1.woff) format("woff")
}

/* Padauk - Burmese*/
@font-face {
  font-family: 'Padauk';
  font-weight: 400;
  font-style: normal;
  src: url(Padauk-Regular.ttf) format("ttf")
}

/* SamsungOneThai */

@font-face {
  font-family: 'SamsungOneThai';
  font-style: normal;
  font-weight: 300;
  src: url(SamsungOneThai/SamsungOneThai-450_v2.0_20181214.eot) format("eot"),
    url(SamsungOneThai/SamsungOneThai-450_v2.0_20181214.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneThai';
  font-style: normal;
  font-weight: 400;
  src: url(SamsungOneThai/SamsungOneThai-450_v2.0_20181214.eot) format("eot"),
    url(SamsungOneThai/SamsungOneThai-450_v2.0_20181214.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneThai';
  font-style: normal;
  font-weight: 500;
  src: url(SamsungOneThai/SamsungOneThai-450_v2.0_20181214.eot) format("eot"),
    url(SamsungOneThai/SamsungOneThai-450_v2.0_20181214.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneThai';
  font-style: normal;
  font-weight: bold;
  src: url(SamsungOneThai/SamsungOneThai-700_v2.0_20181214.eot) format("eot"),
    url(SamsungOneThai/SamsungOneThai-700_v2.0_20181214.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneThai';
  font-style: normal;
  font-weight: 700;
  src: url(SamsungOneThai/SamsungOneThai-700_v2.0_20181214.eot) format("eot"),
    url(SamsungOneThai/SamsungOneThai-700_v2.0_20181214.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneThai';
  font-style: normal;
  font-weight: 800;
  src: url(SamsungOneThai/SamsungOneThai-700_v2.0_20181214.eot) format("eot"),
    url(SamsungOneThai/SamsungOneThai-700_v2.0_20181214.woff) format("woff")
}

/* SamsungOneLatin */

@font-face {
  font-family: 'SamsungOneLatin';
  font-style: normal;
  font-weight: 500;
  src: url(SamsungOneLatin/SamsungOneLatin-500_v2.0_20200508.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneLatin';
  font-style: normal;
  font-weight: 600;
  src: url(SamsungOneLatin/SamsungOneLatin-600_v2.0_20200508.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneLatin';
  font-style: normal;
  font-weight: bold;
  src: url(SamsungOneLatin/SamsungOneLatin-700_v2.0_20200508.woff) format("woff")
}

@font-face {
  font-family: 'SamsungOneLatin';
  font-style: normal;
  font-weight: 800;
  src: url(SamsungOneLatin/SamsungOneLatin-800_v2.0_20200508.woff) format("woff")
}

/* SamsungSharp */

@font-face {
  font-family: 'SamsungSharpSans';
  font-style: normal;
  font-weight: 400;
  src: url(SamsungSharp/SamsungSharpSans-medium.woff2) format("woff2");
}

@font-face {
  font-family: 'SamsungSharpSansBold';
  font-style: normal;
  font-weight: 700;
  src: url(SamsungSharp/SamsungSharpSans-bold.woff2) format("woff2");
}

@font-face {
  font-family: 'SamsungSharpSans';
  font-style: normal;
  font-weight: bold;
  src: url('SamsungSharp/SamsungSharpSans-Bold.eot');
  src: url('SamsungSharp/SamsungSharpSans-Bold.eot?#iefix') format("embedded-opentype"),
    url('SamsungSharp/SamsungSharpSans-bold.woff2') format("woff2"),
    url('SamsungSharp/SamsungSharpSans-Bold.woff') format("woff"),
    url('SamsungSharp/SamsungSharpSans-Bold.ttf') format("truetype");
}
