@import "src/assets/styles/customs/variables";

.local-privacy-modal {
  .col {
    margin-bottom: 22px;
    display: flex;

    label {
      align-items: center;

      & > span {
        color: $linkTextColor6;
      }
    }
  }
}
