@import "src/assets/styles/customs/variables";

.global-site__container {
  padding: 25px 40px;
  border-radius: 12px;
  text-align: center;
  margin-top: 16px;
  background-color: $toastBackground;
  // background: url(#{$mapSmallBackground});
  display: flex;
  align-items: center;
  position: relative;

  &__info {
    margin-left: 8px;
    display: flex;
    align-items: center;
    color: $linkTextColor5;
    flex: 1;

    [dir="rtl"] {
      margin-left: 0;
      margin-right: 8px;
    }

    &__link {
      font-size: 16px !important;
      font-weight: 700;
      line-height: 1.33 !important;
      margin-right: 8px;
      color: $linkTextColor4 !important;
      text-decoration: underline;

      [dir="rtl"] {
        margin-left: 8px;
        margin-right: 0;
      }

      &:focus-visible {
        outline: 2px solid $helpTipsOutline;
      }

      & > span {
        color: $white !important;
        border-bottom: solid 2px $white !important;
      }
    }
  }

  &__close {
    position: absolute;
    right: 24px;
    cursor: pointer;
    color: $linkTextColor4;
    border: none;
    padding: 0;
    background-color: transparent;

    [dir="rtl"] & {
      right: initial;
      left: 24px;
    }

    &:focus-visible {
      outline: 2px solid $helpTipsOutline;
    }

    & svg {
      fill: $white;
    }
  }

  @media (max-width: $mobileMaxWidth) {
    padding: 24px;
    margin: 0 -24px;
    border-radius: 0;
    display: block;

    & > .svg {
      margin: 0 auto 12px;
    }

    &__info {
      display: block;
      align-items: initial;
      text-align: center;
      margin-left: 28px;
      margin-right: 24px;

      &__link {
        margin-top: 3px;
        font-size: 16px !important;
        text-decoration: underline;

        & > span {
          border-bottom: 0 !important;
        }
      }
    }

    &__close {
      top: 24px;
    }
  }
}
