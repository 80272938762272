@import "src/assets/styles/customs/variables";
.surveyModalView {
  display: none !important;
}

.survey-modal {
  .modal-content {
    padding: 0 24px;
    .modal {
      max-height: 660px;

      @media (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
        max-height: 580px;
      }
    }

    padding: 24px;
    .modal-header {
      display: flex;
      align-items: center;

      & .modal-title {
        flex: 1 0;
        margin-right: 30px;
        & > .title2 {
          font-size: 24px !important;
          font-family: $sharpSansFont !important;
        }
        @media (max-width: $mobileMaxWidth) {
          & > .title2 {
            font-size: 22px !important;
            font-family: $sharpSansFont !important;
          }
        }
      }

      &__close__btn {
        display: flex;
        cursor: pointer;
        @media (max-width: $mobileMaxWidth) {
          place-self: baseline;
        }
      }
    }

    .modal-footer {
      height: 40px;
      .button {
        padding: 0px !important;
      }
    }

    .modal-body {
      margin: 32px 0px !important;
    }

    @media (max-width: $mobileMaxWidth) {
      .modal-body {
        margin: 24px 0 32px !important;
      }

      .modal-footer {
        height: 36px;
        .button {
          padding: 0px !important;
        }
      }
    }
  }

  &__top-content {
    width: 100%;
    border: solid 1px $drawerLine;
    border-radius: 12px;
    padding: 16px 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 74px;
  }

  &__question {
    &__text {
      margin: 32px 0px 24px;
    }
  }

  &__form-container {
    width: 100%;
    display: flex;
    place-content: space-around;

    &__wrapper {
      flex: 1;
    }
  }

  &__textarea {
    margin-top: 25px;
    height: 84px !important;
    background-color: $defaultBackground;

    [data-theme="hc"] & {
      color: $textColor;

      &::placeholder {
        color: $textColor;
      }
    }
  }

  @media (max-width: $mobileMaxWidth) {
    &__top-content {
      width: 100%;
      border: solid 1px $drawerLine;
      border-radius: 12px;
      padding: 16px 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 71px;
    }

    &__question {
      &__text {
        margin: 24px 0px 16px;
      }
    }

    &__textarea {
      margin-top: 16px;
      height: 81px !important;
    }
  }
}
