@import "src/assets/styles/customs/variables";

.language-modal {
  & .col {
    margin-bottom: 22px;
    display: flex;

    & label {
      align-items: center;

      & > span {
        color: $linkTextColor6;
      }
    }
    & .checkbox--withoutSelectBg > span {
      font-weight: normal !important;
    }

    &.active {
      & label > span {
        color: $linkTextColor3;

        [data-theme="hc"] & {
          color: $linkTextColor6;
        }
      }
    }
  }

  @media (max-width: $mobileMaxWidth) {
    & .modal-content {
      position: fixed !important;
      top: initial !important;
      margin: 0 24px;
      @media (max-width: $mobileUnsupportedWidth) {
        margin: 0;
      }
      & .modal-body {
        margin: 29px -24px !important;

        & .accordion-item {
          &__title {
            padding: 17px 24px 19px;
          }

          &__content {
            padding: 30px 24px;
          }
        }
      }
    }
  }
}
