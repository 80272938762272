@import "src/assets/styles/customs/variables";

.loading-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: $defaultBackground;

  &__spinner {
    margin: 0 auto;
    display: block;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
}
