@import "src/assets/styles/customs/variables";

.country-specific {
  @media (max-width: $mobileMaxWidth) {
    padding: 0;
  }

  &__title {
    font-size: 52px;
    line-height: 1.23;
    margin: 40px 0;
    @media (max-width: $mobileMaxWidth) {
      overflow-x: auto;
      font-size: 32px;
      margin: 15px 0;
      html[lang="ru_RU"] & {
        font-size: 24px;
      }
      html[lang="ru"] & {
        font-size: 24px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @media (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
      font-size: 48px;
    }
  }
  &__title-scenario3 {
    font-size: 56px;
    margin: 40px 0;
    @media (max-width: $mobileMaxWidth) {
      overflow-x: auto;
      font-size: 32px;
      margin: 15px 0;
      html[lang="ru_RU"] & {
        font-size: 24px;
      }
      html[lang="ru"] & {
        font-size: 24px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @media (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
      font-size: 48px;
    }
  }

  &__your-rights {
    background: url(#{$hubPrivacyBg}), $blue70;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 90%;
    padding: 40px;
    border-radius: 26px;
    margin: 40px 0;

    @media (max-width: $mobileMaxWidth) {
      padding: 20px;
      background: $blue70;
      margin-top: 15px;
    }

    & .typography {
      color: $linkTextColor5;
    }

    &__desc {
      margin-top: 24px;

      & + .country-specific__your-rights__desc {
        margin-top: 0;
        margin-bottom: 24px;
      }
      @media (max-width: $mobileMaxWidth) {
        font-size: 14px;
      }
    }

    &__button {
      border: none;
      color: $linkTextColor;
      span {
        font-size: 18px;
        line-height: 1.33;
        font-weight: 500;
      }

      &:disabled {
        cursor: default;
      }

      & svg {
        fill: none !important;
      }

      [data-theme="hc"] & {
        background-color: $gray31;
        border: 1px solid $linkTextColor;
      }
    }
  }
}
