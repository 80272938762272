@import "src/assets/styles/customs/variables";

.service-carousel-container {
  overflow: hidden;
  position: relative;
  margin: 0 32px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  width: 100%;
  &__arrow-left {
    position: absolute;
    left: 0;
    cursor: pointer;
    color: $arrowColor;
  }

  &__arrow-right {
    position: absolute;
    right: 0;
    transform: rotateY(180deg);
    cursor: pointer;
    color: $arrowColor;
  }

  &::after {
    position: absolute;
    content: " ";
    right: 24px;
    top: 0;
    z-index: 9;
    height: 100%;
    width: 16px;
    background-image: linear-gradient(
      to right,
      rgb(255 255 255 / 0%) 0%,
      $shadow 100%
    );
  }

  &::before {
    position: absolute;
    content: " ";
    left: 24px;
    top: 0;
    z-index: 9;
    height: 100%;
    width: 16px;
    background-image: linear-gradient(
      to left,
      rgb(255 255 255 / 0%) 0%,
      $shadow 100%
    );
  }

  &.max-left {
    & .service-carousel-container {
      &__arrow-left {
        display: none;
      }
    }

    &::before {
      display: none;
    }
  }

  &.max-right {
    & .service-carousel-container {
      &__arrow-right {
        display: none;
      }
    }

    &::after {
      display: none;
    }
  }

  @media (max-width: $mobileMaxWidth) {
    margin: 0;
    padding: 0;

    &::before,
    &::after {
      display: none;
    }

    &__arrow-left {
      position: fixed;
      left: 36px;
    }

    &__arrow-right {
      position: fixed;
      right: 36px;
    }
  }
}

.service-carousel {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  margin-bottom: -3.5px;

  &__item {
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 28px;
    background-color: $background1;
    margin: 0 4px;
    cursor: pointer;

    &__icon {
      display: flex;

      img {
        border-radius: 50%;
      }
    }

    &__name__text {
      font-weight: bold;
      margin-left: 8px;
      white-space: nowrap;

      [dir="rtl"] & {
        margin-left: 0;
        margin-right: 8px;
      }
    }

    &__close {
      margin-left: 8px;
      cursor: pointer;
      position: relative;

      [dir="rtl"] & {
        margin-left: 0;
        margin-right: 8px;
      }

      .svg {
        color: $linkTextColor;
      }

      button {
        background: none;
        border: none;
        width: 16px;
        height: 16px;
        top: -4px;
        position: absolute;
        padding: 0;
        cursor: pointer;
        right: -4px;

        &:focus-visible {
          outline: 2px solid $point700;
        }
      }
    }
  }
}
