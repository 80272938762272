@import "src/assets/styles/customs/variables";

.white-text {
  color: $whiteTextColor;

  [dir="rtl"] & {
    svg {
      @media (min-width: $tabletLandscapeMinWidth) {
        transform: rotate(180deg);
      }
    }
  }

  &__subText {
    color: $whiteTextColor;
    opacity: 0.8 !important;
  }
}

.transparent {
  background-color: transparent !important;
  @media (max-width: $mobileMaxWidth) {
    display: none !important;
  }
}

.caption-2 {
  @media (min-width: $desktopMinWidth) {
    width: 268px !important;
  }

  @media (max-width: $mobileMaxWidth) {
    width: auto !important;
  }
}

.steps-tag {
  @media (min-width: $desktopMinWidth) {
    width: 72px !important;
    height: 72px !important;
  }

  @media (min-width: $tabletLandscapeMinWidth) {
    width: 72px !important;
    height: 72px !important;
  }

  @media (max-width: $tabletPortraitMaxWidth) {
    width: 72px !important;
    height: 72px !important;
  }

  @media (max-width: $mobileMaxWidth) {
    width: 48px !important;
    height: 48px !important;
  }
}

.reverse-caption {
  @media (max-width: $mobileMaxWidth) {
    flex-direction: column-reverse !important;
  }
}

.right-caption {
  @media (min-width: $desktopMinWidth) {
    padding: 0px 218px 0px 80px !important;
  }

  @media (min-width: $tabletLandscapeMinWidth) {
    padding: 0px 116px 0px 80px;
  }
  @media (max-width: $tabletPortraitMaxWidth) {
    padding: 0px 0px 0px 80px !important;
  }

  @media (max-width: $mobileMaxWidth) {
    padding: 0px !important;
  }

  [dir="rtl"] & {
    @media (min-width: $desktopMinWidth) {
      padding: 0px 80px 0px 218px !important;
    }

    @media (min-width: $tabletLandscapeMinWidth) {
      padding: 0px 80px 0px 116px !important;
    }

    @media (max-width: $tabletPortraitMaxWidth) {
      padding: 0px 80px 0px 0px !important;
    }
  }
}

.left-caption {
  @media (min-width: $desktopMinWidth) {
    padding: 0px 80px 0px 218px !important;
  }
  @media (min-width: $tabletLandscapeMinWidth) {
    padding: 0px 80px 0px 116px !important;
  }
  @media (max-width: $tabletPortraitMaxWidth) {
    padding: 0px 80px 0px 0px !important;
  }
  @media (max-width: $mobileMaxWidth) {
    padding: 0px !important;
  }
}

.background {
  [data-theme="hc"] & {
    background-color: $gray31;
  }
}

.steps-overview-container {
  background-color: red($color: #000000);
  width: 100%;
  height: 100%;
}

.overview-top {
  [data-theme="hc"] & {
    background-color: $gray31;

    &__steps {
      background-color: $gray4a;

      &__step {
        .typography {
          color: $textColor !important;
        }
      }

      &__arrow__icon {
        span {
          color: $textColor;
        }

        span path {
          opacity: 1;
        }
      }
    }
  }

  @media (min-width: $desktopMinWidth) {
    height: fit-content;
    padding: 80px 36px;
    text-align: center;
    display: flex;
    flex-direction: column;

    &__title {
      text-align: center;
      font-family: $sharpSansFont;
      margin-bottom: 12px;
    }

    &__caption {
      font-weight: 400 !important;
      margin-bottom: 70px;
    }

    &__icon {
      margin: 0 auto -41px !important;
      height: 490px !important;
      width: 640px !important;
    }

    &__steps {
      border-radius: 20px;
      background-color: $point700;
      width: 100%;
      // height: 281px;
      padding: 64px 96px 62px;
      display: flex;
      flex-direction: row;
      place-content: space-between;

      &__step {
        width: fit-content;
        height: 100%;
        display: flex;
        align-self: center;

        &__caption {
          flex-direction: column;
          width: 224px;

          &__step-number {
            font-family: $sharpSansFont !important;
          }

          &__title {
            text-align: center;
            // margin: 2px 0px 20px;
          }
        }
      }

      &__arrow {
        margin: 0px 113px 0px;
        display: flex;
        align-items: center;
      }
    }
  }

  @media (min-width: $tabletLandscapeMinWidth) {
    height: fit-content;
    padding: 80px 36px;
    text-align: center;
    display: flex;
    flex-direction: column;

    &__title {
      text-align: center;
      font-family: $sharpSansFont;
      margin-bottom: 12px;
    }

    &__caption {
      font-weight: 400 !important;
      margin-bottom: 70px;
    }

    &__icon {
      margin: 0 auto -41px !important;
      height: 490px !important;
      width: 640px !important;
    }

    &__steps {
      border-radius: 20px;
      background-color: $point700;
      width: 100%;
      // height: 281px;
      padding: 64px 96px 62px;
      display: flex;
      flex-direction: row;
      place-content: space-between;

      &__step {
        width: fit-content;
        height: 100%;
        display: flex;
        align-self: center;
        align-items: center;

        &__caption {
          flex-direction: column;

          &__step-number {
            font-family: $sharpSansFont !important;
          }

          &__title {
            text-align: center;
            // margin: 2px 0px 20px;
          }
        }
      }

      &__arrow {
        margin: 0px 65px;
        display: flex;
        align-items: center;
      }
    }
  }

  @media (min-width: $tabletPortraitMinWidth) {
    height: fit-content;
    padding: 80px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;

    &__title {
      text-align: center;
      font-family: $sharpSansFont;
      margin-bottom: 12px;
    }

    &__caption {
      font-weight: 400 !important;
      margin-bottom: 70px;
    }

    &__icon {
      margin: 0 auto !important;
      height: 492px !important;
      width: 641px !important;
    }

    &__steps {
      border-radius: 20px;
      background-color: $point700;
      width: 100%;
      height: auto;
      padding: 40px 47px;
      display: flex;
      flex-direction: column;

      &__step {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &__caption {
          flex-direction: column;

          &__step-number {
            font-family: $sharpSansFont !important;
          }

          &__title {
            text-align: center;
            margin: 0px 0px 4px;
          }
        }
      }

      &__arrow {
        margin: 32px auto;
        display: flex;
        align-items: center;

        &__icon {
          color: $white;
          width: 32px !important;
          height: 16px !important;
        }
      }
    }
  }

  @media (max-width: $mobileMaxWidth) {
    height: fit-content;
    padding: 40px 12px;
    text-align: center;
    display: flex;
    flex-direction: column;

    &__title {
      text-align: center;
      font-family: $sharpSansFont;
      margin-bottom: 8px;
    }

    &__caption {
      font-weight: 400 !important;
      margin-bottom: 32px;
    }

    &__icon {
      margin: 0 auto !important;
      height: 240px !important;
      width: 100% !important;
    }

    &__steps {
      border-radius: 20px;
      background-color: $point700;
      width: 100%;
      height: auto;
      padding: 40px 47px;
      display: flex;
      flex-direction: column;

      &__step {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &__caption {
          flex-direction: column;

          &__step-number {
            font-family: $sharpSansFont !important;
          }

          &__title {
            text-align: center;
            margin: 0px 0px 4px;
          }
        }
      }

      &__arrow {
        margin: 32px auto;
        display: flex;
        align-items: center;

        &__icon {
          color: $white;
          width: 20px !important;
          height: 10px !important;
        }
      }
    }
  }
}

.overview-step1 {
  height: fit-content;
  width: 100%;
  position: relative;
  background-color: $point50;

  [data-theme="hc"] & {
    background-color: $gray3c;

    &__container__card {
      background-color: $gray31;
    }
  }

  @media (min-width: $desktopMinWidth) {
    padding: 80px 0px;

    &__container {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;

      &__row {
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 100%;
      }

      &__col {
        padding-top: 24px;
      }

      &__title {
        text-align: center;
        margin: 12px 0px 24px;
        font-family: $sharpSansFont;
      }

      &__card {
        background-color: $white;
        padding: 60px;
        // min-width: 448px;
        height: 371px;
        border-radius: 20px;

        &__icon {
          width: 96px !important;
          height: 96px !important;
        }

        &__title {
          margin: 40px 0px 12px;
        }

        &__caption {
          font-weight: 400;
        }
      }

      &__table {
        width: 279px;
        height: 223px;
        right: 24px;
        bottom: -80px;
        position: absolute;
      }
    }
  }

  @media (min-width: $tabletLandscapeMinWidth) {
    padding: 80px 0px;

    &__container {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;

      &__row {
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 100%;
      }

      &__col {
        padding-top: 24px;
      }

      &__title {
        text-align: center;
        margin: 12px 0px 24px;
        font-family: $sharpSansFont;
      }

      &__card {
        background-color: $white;
        padding: 60px;
        width: 100%;
        height: 100%;
        border-radius: 20px;

        &__icon {
          width: 96px !important;
          height: 96px !important;
        }

        &__title {
          margin: 40px 0px 12px;
        }

        &__caption {
          font-weight: 400;
        }
      }

      &__table {
        width: 279px !important;
        height: 223px !important;
        right: 24px !important;
        bottom: -80px !important;
        position: absolute;

        [dir="rtl"] & {
          left: 24px !important;
          right: initial !important;
        }
      }
    }
  }

  @media (min-width: $tabletPortraitMinWidth) {
    padding: 80px 0px;

    &__container {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;

      &__row {
        margin-left: 0px !important;
        margin-right: 0px !important;
        // place-content: space-between;
        width: 100%;
      }

      &__col {
        padding-top: 24px;
      }

      &__title {
        text-align: center;
        margin: 12px 0px 24px;
        font-family: $sharpSansFont;
      }

      &__card {
        background-color: $white;
        padding: 60px;
        height: 100%;
        border-radius: 20px;

        &__icon {
          width: 96px !important;
          height: 96px !important;
        }

        &__title {
          margin: 40px 0px 12px;
        }

        &__caption {
          font-weight: 400;
        }
      }

      &__table {
        width: 279px !important;
        height: 223px !important;
        right: 24px !important;
        bottom: -80px !important;
        position: absolute !important;

        [dir="rtl"] & {
          left: 24px !important;
          right: initial !important;
        }
      }
    }
  }

  @media (max-width: $mobileMaxWidth) {
    padding: 40px 0px 179px;

    &__container {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;

      &__row {
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 100%;
      }

      &__title {
        text-align: center;
        margin: 12px 0px 24px;
        font-family: $sharpSansFont;
      }
      &__col {
        padding-top: 0px;
      }

      &__card {
        background-color: $white;
        padding: 34px 25px;
        width: 100%;
        height: fit-content;
        border-radius: 20px;
        margin: 0px 0px 16px !important;
        flex-direction: row;
        display: flex;

        &__icon {
          width: 64px !important;
          height: 64px !important;
          min-width: 64px !important;
          align-self: center !important;
        }

        &__desc {
          margin-left: 20px;
          height: fit-content;

          [dir="rtl"] & {
            margin-left: 0;
            margin-right: 20px;
          }
        }

        &__title {
          margin: 0px !important;
        }

        &__caption {
          margin-top: 8px;
          font-weight: 400;
        }
      }

      &__table {
        width: 160px !important;
        height: 127px !important;
        right: 24px !important;
        bottom: -179px !important;
        position: absolute;
      }
    }
  }
}

.overview-step2 {
  [data-theme="hc"] & {
    background-color: $gray31;

    &__section__card {
      background-color: $gray4a;
    }
  }

  @media (min-width: $desktopMinWidth) {
    padding: 100px 24px 0px;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
      margin: 12px 0px 40px;
      text-align: center;
      font-family: $sharpSansFont;
    }

    &__row {
      padding-bottom: 100px;
      width: 100%;
      margin: 0 !important;
      place-content: space-between;
    }

    &__section {
      width: 684px;

      &__icon {
        width: 320px !important;
        height: 320px !important;
      }

      &__card {
        background-color: $point50;
        width: 100%;
        height: 336px;
        margin: 0 0 32px !important;
        padding: 8px 182px;
        border-radius: 20px;
      }

      &__caption {
        text-align: center;
        &__title {
          text-align: center;
          margin-bottom: 12px;
        }

        &__desc {
          font-weight: 400;
        }
      }
    }
  }

  @media (min-width: $tabletLandscapeMinWidth) {
    padding: 100px 24px 0px;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
      margin: 12px 0px 40px;
      text-align: center;
      font-family: $sharpSansFont;
    }

    &__row {
      width: 100%;
      margin: 0 !important;
      place-content: space-between;
      border-bottom: solid 1px $drawerLine !important;
      padding-bottom: 100px;
    }

    &__section {
      width: 684px;

      &__icon {
        width: 320px !important;
        height: 320px !important;
        margin: 0px auto;
        align-self: center;
      }

      &__card {
        background-color: $grayF7;
        width: 100%;
        height: 336px;
        margin: 0 0 32px !important;
        border-radius: 20px;
      }

      &__caption {
        text-align: center;
        &__title {
          text-align: center;
          margin-bottom: 12px;
        }

        &__desc {
          font-weight: 400;
        }
      }
    }
  }

  @media (min-width: $tabletPortraitMinWidth) {
    padding: 100px 24px 0px;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
      margin: 12px 0px 40px;
      text-align: center;
      font-family: $sharpSansFont;
    }

    &__row {
      width: 100%;
      margin: 0 !important;
      place-content: space-between;
      border-bottom: solid 1px $drawerLine !important;
      padding-bottom: 100px;
    }

    &__section {
      &__icon {
        width: 320px !important;
        height: 320px !important;
        margin: 0px auto !important;
        align-self: center;
      }

      &__card {
        background-color: $grayF7;
        width: 100%;
        height: 336px;
        margin: 0 0 32px !important;
        border-radius: 20px;
      }

      &__caption {
        text-align: center;
        &__title {
          text-align: center;
          margin-bottom: 12px;
        }

        &__desc {
          font-weight: 400;
        }
      }
    }
  }

  @media (max-width: $mobileMaxWidth) {
    padding: 40px 24px 0px;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-bottom: none !important;

    &__title {
      margin: 12px 0px 40px;
      text-align: center;
      font-family: $sharpSansFont;
    }

    &__row {
      width: 100%;
      margin: 0px 40px !important;
      place-content: space-between;
      border-bottom: solid 1px $drawerLine !important;
      padding-bottom: 8px !important;
    }

    &__section {
      width: 100% !important;

      &__icon {
        width: 180px !important;
        height: 160px !important;
        margin: 0px auto;
      }

      &__card {
        background-color: $grayF7;
        width: 100%;
        height: 212px;
        margin: 0 0 20px !important;
        padding: 26px 0px;
        border-radius: 20px;
      }

      &__caption {
        text-align: center;
        &__title {
          text-align: center;
          margin-bottom: 8px;
        }

        &__desc {
          font-weight: 400;
          margin-bottom: 40px;
        }
      }
    }
  }
}

.overview-step3 {
  [data-theme="hc"] & {
    &__section__card {
      background-color: $gray4a;
    }
  }

  @media (min-width: $desktopMinWidth) {
    padding: 100px 24px 176px;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    &__title {
      text-align: center;
      margin: 12px 0px 48px;
      font-family: $sharpSansFont;
    }

    &__row {
      width: 100%;
      margin: 0 !important;
      place-content: space-between;
    }

    &__section {
      display: flex;
      flex-direction: row;
      margin-bottom: 24px;

      &__card {
        background-color: rgba($point50, 0.4);
        min-width: 684px;
        height: 336px;
        border-radius: 20px;
        display: flex;

        &__icon {
          margin: auto;
          width: 320px !important;
          height: 320px !important;
        }
      }

      &__caption {
        align-self: center;

        &__title {
          margin-bottom: 12px;
        }

        &__desc {
          font-weight: 400;
        }
      }
    }
  }

  @media (min-width: $tabletLandscapeMinWidth) {
    padding: 100px 24px 176px;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    &__title {
      text-align: center;
      margin: 12px 0px 48px;
      font-family: $sharpSansFont;
    }

    &__row {
      width: 100%;
      margin: 0 !important;
      place-content: space-between;
    }

    &__section {
      display: flex;
      flex-direction: row;
      margin-bottom: 24px;

      &__card {
        background-color: rgba($point50, 0.4);
        min-width: 50%;
        height: 336px;
        border-radius: 20px;
        display: flex;

        &__icon {
          margin: auto;
          width: 320px !important;
          height: 320px !important;
        }
      }

      &__caption {
        align-self: center;

        &__title {
          margin-bottom: 12px;
        }

        &__desc {
          font-weight: 400;
        }
      }
    }
  }

  @media (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
    padding: 100px 24px 176px;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    &__title {
      text-align: center;
      margin: 12px 0px 48px;
      font-family: $sharpSansFont;
    }

    &__row {
      width: 100%;
      margin: 0 !important;
      place-content: space-between;
    }

    &__section {
      display: flex;
      flex-direction: row;
      place-content: space-between;
      margin-bottom: 24px;

      &__card {
        background-color: rgba($point50, 0.4);
        width: 50% !important;
        max-width: none !important;
        height: 336px;
        border-radius: 20px;
        display: flex;

        &__icon {
          margin: auto;
          width: 320px !important;
          height: 320px !important;
        }
      }

      &__caption {
        align-self: center;
        max-width: 416px !important;
        width: auto !important;
        &__title {
          margin-bottom: 12px;
        }

        &__desc {
          font-weight: 400;
        }
      }
    }
  }

  @media (max-width: $mobileMaxWidth) {
    padding: 40px 24px !important;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    &__title {
      text-align: center;
      margin: 12px 0px 29px;
      font-family: $sharpSansFont;
    }

    &__row {
      width: 100%;
      margin: 0 !important;
      place-content: space-between;
    }

    &__section {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      &__card {
        background-color: rgba($point50, 0.4);
        height: 212px;
        border-radius: 20px;
        display: flex;
        margin-bottom: 20px;
        width: 100% !important;
        max-width: none !important;

        &__icon {
          margin: 0px auto;
          align-self: center;
          width: 180px !important;
          height: 180px !important;
        }
      }

      &__caption {
        align-self: center;

        &__title {
          text-align: center;
          margin-bottom: 12px;
        }

        &__desc {
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }
}

.overview-bottom {
  background-color: $blue50;
  &__content {
    z-index: 2;
  }
  &__content > button {
    background-color: transparent;
    border: none;

    &:focus-visible {
      outline: 2px solid $point700;
    }
  }

  &__button-container:has(:focus-visible) {
    outline: 2px solid $point700;
  }

  &__button:focus {
    outline: none;
  }

  [data-theme="hc"] & {
    background-color: $gray3c;

    &__button {
      color: $linkTextColor;
      border-color: $linkTextColor;
    }
  }

  @media (min-width: $desktopMinWidth) {
    height: 336px;
    padding: 66px 0px 67px;

    &__container {
      display: flex;
      flex-direction: row;
      position: relative;
      color: $white;
      height: 100%;
      align-items: center;
    }

    &__content {
      z-index: 2;
      text-align: center;
      width: 100%;

      &__title {
        margin-bottom: 16px;
      }

      &__caption {
        font-weight: 400;
        margin-bottom: 32px;
        font-size: 16px;
      }
    }

    &__button {
      background-color: $transparent;
      color: $white;
      border-color: $white;
    }

    &__icon-left {
      opacity: 0.7;
      position: absolute;
      left: 24px !important;
    }

    &__icon-right {
      opacity: 0.7;
      position: absolute;

      [dir="rtl"] & {
        right: 24px !important;
      }
    }
  }

  @media (min-width: $tabletLandscapeMinWidth) {
    height: 336px;
    padding: 66px 0px 67px;

    &__container {
      display: flex;
      flex-direction: row;
      position: relative;
      color: $white;
      height: 100%;
      align-items: center;
    }

    &__content {
      z-index: 2;
      text-align: center;
      width: 100%;

      &__title {
        margin-bottom: 16px;
      }

      &__caption {
        font-weight: 400;
        margin-bottom: 32px;
        font-size: 16px;
      }
    }

    &__button {
      background-color: $transparent;
      color: $white;
      border-color: $white;

      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
        color: $grayDd;
        border-color: $grayDd;

        [data-theme="hc"] & {
          background-color: $transparent;
          color: $textColor;
          border-color: $textColor;
        }
      }
    }

    &__icon-left {
      opacity: 0.7;
      position: absolute;
      left: 24px;
    }

    &__icon-right {
      opacity: 0.7;
      position: absolute;
      right: 24px;
    }
  }

  @media (min-width: $tabletPortraitMinWidth) {
    height: 336px;
    padding: 66px 0px 67px;

    &__container {
      display: flex;
      flex-direction: row;
      position: relative;
      color: $white;
      height: 100%;
      align-items: center;
    }

    &__content {
      z-index: 2;
      text-align: center;
      width: 100%;

      &__title {
        margin-bottom: 16px;
      }

      &__caption {
        font-weight: 400;
        margin-bottom: 32px;
        font-size: 16px;
      }
    }

    &__button {
      background-color: $transparent;
      color: $white;
      border-color: $white;
    }

    &__icon-left {
      opacity: 0.7;
      position: absolute;
      left: 24px;
    }

    &__icon-right {
      opacity: 0.7;
      position: absolute;
      right: 24px;
    }
  }
  @media (max-width: $mobileMaxWidth) {
    height: fit-content;
    padding: 44px 24px 70px;

    &__container {
      display: flex;
      flex-direction: row;
      position: relative;
      color: $white;
      height: 100%;
      align-items: center;
    }

    &__content {
      text-align: center;
      width: 100%;

      &__title {
        margin-bottom: 16px;
      }

      &__caption {
        font-weight: 400;
        margin-bottom: 32px;
        font-size: 15px;
      }
    }

    &__button {
      background-color: $transparent;
      color: $white;
      border-color: $white;
    }

    &__icon-left {
      position: absolute;
      left: -24px;
    }

    &__icon-right {
      position: absolute;
      right: -24px;
    }
  }
}

.table {
  display: flex;
  width: 100%;
  height: 281px;
  gap: 1rem;
  justify-content: space-between;
  background-color: $point700;
  padding: 64px 96px 62px;
  border-radius: 20px;
  [data-theme="hc"] & {
    background-color: $gray4a;
  }

  .arrow-container {
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .steps-col-container {
    justify-content: center;
    display: flex;
    width: 300px;
    flex-direction: column;
    align-items: center;

    .step-description {
      width: 80%;
    }
  }
}
