@import "src/assets/styles/customs/variables";

.checkboxImage {
  width: 64px !important;
  height: 64px !important;
  @media (max-width: $mobileMaxWidth) {
    width: 40px !important;
    height: 40px !important;
  }
}

.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: $transparentRGBA;
  -webkit-tap-highlight-color: $transparent;

  input[type="checkbox"] {
    // visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
  }

  &:has(:focus-visible) {
    outline: 2px solid $point700;
  }

  &--general {
    > span {
      font-weight: 400;
      line-height: 2px;
      text-align: left;
      color: $gray75;
      margin-left: 8px;

      [dir="rtl"] & {
        margin-left: 0;
        margin-right: 8px;
      }
    }

    @media (min-width: $desktopMinWidth) {
      /* Target desktop screens */
      &:hover:before {
        content: " ";
        background: url(#{$iconCheckboxHover});
        background-size: contain;

        [data-theme="hc"] & {
          background: url(#{$iconCheckboxHoverHc});
          background-size: contain;
        }
      }
    }

    &:before {
      content: " ";
      width: 24px;
      height: 24px;
      background: url(#{$iconCheckboxOff});
      border-radius: 50%;

      [data-theme="hc"] & {
        background: url(#{$iconCheckboxOffHc});
      }
    }

    &--selected {
      &:hover:before {
        content: " ";
        background: url(#{$iconCheckboxSelected});

        [data-theme="hc"] & {
          background: url(#{$iconCheckboxSelectedHc});
        }
      }

      &:before {
        content: " ";
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: url(#{$iconCheckboxSelected});

        [data-theme="hc"] & {
          background: url(#{$iconCheckboxSelectedHc});
        }
      }

      > span {
        font-weight: 700;
        color: $blk;
      }
    }

    &--disabled {
      cursor: default;

      &:before,
      &:hover:before {
        content: " ";
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: url(#{$iconCheckboxDisabled});

        [data-theme="hc"] & {
          background: url(#{$iconCheckboxDisabledHc});
        }
      }
    }
  }

  &--selectBg {
    height: 56px;
    width: 100%;
    background-color: $white;
    border-top: solid 1px $drawerLine;
    border-bottom: solid 1px $drawerLine;
    padding: 17px 16px 19px 16px;

    > span {
      font-family: $sharpSansFont;
      font-size: 16px;
      line-height: 24px;
      color: $gray75;
    }

    &--selected {
      background-color: $point50;

      &:after {
        content: " ";
        width: 16px;
        height: 16px;
        background: url(#{$iconCheckboxCheck});
        margin: 0 8px 0 0;
        position: absolute;
        right: 0;
      }

      span {
        color: $point;
      }
    }
  }

  &--withoutSelectBg {
    width: 100%;
    background-color: transparent;

    > span {
      font-family: $sharpSansFont;
      font-weight: 500;
      color: $linkTextColor;
      margin-right: 16px;

      [dir="rtl"] & {
        margin-right: 0;
        margin-left: 16px;
      }
    }

    &--selected {
      &:after {
        content: " ";
        width: 16px;
        height: 16px;
        background: url(#{$iconCheckboxCheck});
        margin: 0 0 0 8px;
        right: 0px;
        position: absolute;

        [data-theme="hc"] & {
          background: url(#{$iconCheckboxCheckHc});
        }

        [dir="rtl"] & {
          margin: 0 8px 0 0;
          left: 0;
          right: initial;
        }
      }

      span {
        color: $checkboxSelected;
      }
    }
  }

  &--withImg {
    display: flex;
    flex-direction: column-reverse;

    > span {
      font-weight: 400;
      line-height: 2px;
      text-align: left;
      color: $gray75;
      margin-bottom: 8px;
    }

    &:hover:before {
      content: " ";
      background: url(#{$iconCheckboxHover});
      background-size: contain;

      [data-theme="hc"] & {
        background: url(#{$iconCheckboxHoverHc});
        background-size: contain;
      }
    }

    &:before {
      content: " ";
      width: 24px;
      height: 24px;
      background: url(#{$iconCheckboxOff});
      border-radius: 50%;

      [data-theme="hc"] & {
        background: url(#{$iconCheckboxOffHc});
      }
    }

    &--selected {
      &:hover:before {
        background: url(#{$iconCheckboxSelected});

        [data-theme="hc"] & {
          background: url(#{$iconCheckboxSelectedHc});
        }
      }

      &:before {
        content: " ";
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: url(#{$iconCheckboxSelected});

        [data-theme="hc"] & {
          background: url(#{$iconCheckboxSelectedHc});
        }
      }

      > span {
        font-weight: 700;
        color: $blk;
      }
    }

    &--disabled {
      &:hover:before {
        content: " ";
        background: url(#{$iconCheckboxDisabled});
        background-size: contain;

        [data-theme="hc"] & {
          background: url(#{$iconCheckboxDisabledHc});
        }
      }

      &:before {
        content: " ";
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: url(#{$iconCheckboxDisabled});

        [data-theme="hc"] & {
          background: url(#{$iconCheckboxDisabledHc});
        }
      }
    }
  }
}

.no-text {
  & > span {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
