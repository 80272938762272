@import "variables.scss";

*:lang(ja),
*:lang(zh),
*:lang(zh_MO),
*:lang(zh_CN),
*:lang(zh_HK),
*:lang(zh_TW) {
  font-family: $mainFont;
}

*:lang(fa),
*:lang(ar),
*:lang(ur) {
  font-family: $SamsungOneArabic !important;
}

*:lang(ko) {
  font-family: $SamsungOneKorean;
}

*:lang(he) {
  font-family: $SamsungOneHebrew !important;
}

*:lang(my) {
  font-family: $Padauk;
}

*:lang(th) {
  font-family: $SamsungOneThai !important;
}

body {
  font-family: $mainFont;
  font-size: 16px;
  margin: 0;
  &.alert-open,
  &.modal-open {
    overflow: hidden;
    // padding-right: 10px;
  }
}
@media screen and (max-width: $mobileMaxWidth) {
  body {
    font-size: 14px;

    &.modal-open {
      padding-right: 0;
    }
  }
}
*,
:after,
:before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $linkTextColor;
}

.typography {
  color: $textColor;
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scrollbarTrack);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbarThumb);
  border-radius: 5px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbarThumbHover);
}

.sharp-sans {
  font-family: $sharpSansFont;
}

.firstChild:focus {
  border: none;
  outline-style: none;
}

#root {
  white-space: pre-line;
}

[dir="rtl"] * {
  direction: rtl;
}

[dir="ltr"] * {
  direction: ltr;
}

a:focus-visible {
  outline: 2px solid $point700;
}
