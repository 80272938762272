@import "src/assets/styles/customs/variables";

.mydata-toast {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 16px 32px;
  border-radius: 12px;
  position: fixed;
  bottom: 40px;
  max-width: 1392px;
  background-color: $defaultBackground;
  width: calc(100% - 48px);
  margin: 0 auto;
  z-index: 1;
  display: flex;
  align-items: center;
  border: 1px solid $point700;

  [data-theme="hc"] & {
    border: 1px solid $grayF7;
    @media (max-width: $mobileUnsupportedWidth) {
      position: absolute;
    }
  }

  &__close-container {
    position: relative;

    &__icon {
      position: absolute;
      top: -85px;
      cursor: pointer;
      color: $toastCloseIconText;
      background-color: transparent;
      border: none;
      padding: 0;

      circle {
        color: $toastCloseIconBackground;
      }

      &:focus-visible {
        outline: 2px solid $point700;
      }

      @media (max-width: $mobileMaxWidth) {
        top: -230px;
        right: -35px;
      }
    }
  }

  @media (max-width: $mobileMaxWidth) {
    display: block;
    padding: 24px 32px;
  }
  @media (max-width: $mobileUnsupportedWidth) {
    width: 312px;
    position: absolute;
    .service-carousel-container__arrow-right {
      position: relative;
      right: 0;
    }
    .service-carousel-container__arrow-left {
      position: relative;
      left: 0;
    }
  }
  &__count {
    flex: 1;
    max-width: fit-content;

    &__text {
      font-weight: 700;
      white-space: nowrap;
    }

    &__number {
      font-family: $sharpSansFont;
      text-align: center;
      color: $linkTextColor3;
    }

    @media (max-width: $mobileMaxWidth) {
      margin: 0 auto 19px;

      &__text {
        margin-right: 6px;

        [dir="rtl"] & {
          margin-left: 6px;
          margin-right: 0;
        }
      }

      &__number {
        display: inline-block;
      }
    }
  }

  &__button {
    flex: 2;
    width: 100%;
    display: flex;
    justify-content: right;
    &__text {
      min-width: 82px;
      line-height: 20px !important;

      > span {
        white-space: nowrap;

        @media (max-width: $mobileMaxWidth) {
          font-size: 16px;
        }
      }
    }

    @media (max-width: $mobileMaxWidth) {
      display: block;
      text-align: center;
      padding: 18px 0 0;
      border-top: 1px solid $line;
      margin-top: 16px;
    }
  }
}
