@import "src/assets/styles/customs/variables";

.home-bottom-banner {
  padding: 100px 0px;
  background-color: $grayF7;
  text-align: center;
  scroll-snap-align: start;
  scroll-snap-stop: always;

  &__row {
    place-content: center;
    display: flex;
    flex-direction: row;
    max-width: 1440px;
  }
  &__col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    padding: 32px 61px;
    background-color: $white;
    margin: 0px auto 16px;

    &__icon {
      margin-bottom: 20px;
      display: inline-flex;
    }

    &__title {
      line-height: 1.25;
      margin-bottom: 8px;
    }

    &__caption {
      line-height: 1.33;
      font-weight: 500;
    }
    [data-theme="hc"] & {
      background-color: $gray31;

      &__title {
        color: $linkTextColor;
      }
    }
  }
  &__title {
    font-size: 56px;
    font-family: $mainFont;
  }
  [data-theme="hc"] & {
    background-color: $gray3c;
  }

  @media (max-width: $mobileUnsupportedWidth) {
    &__title {
      font-size: 24px;
    }
  }

  @media (min-width: $desktopMinWidth) {
    padding: 120px 0px 200px;

    &__title {
      max-width: 1156px;
      margin: 0px auto 60px;
    }

    &__row {
      place-content: space-between;
    }

    &__col {
      padding: 0px;
      text-align: center;
      border-radius: 20px;
      padding: 80px 72px;
      background-color: $white;
      width: 100%;
      height: 100%;
      min-height: 383px;
      &__icon {
        margin-bottom: 32px;
        display: inline-flex;
      }

      &__caption {
        margin-top: 16px;
        font-weight: 500;
      }
    }
  }

  @media screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
    padding-bottom: 72px;
    &__title {
      font-size: 24px;
    }
    &__row {
      place-content: center;
      display: flex;
      flex-direction: column;
    }

    &__col {
      &__icon {
        margin-bottom: 20px;
        display: inline-flex;
        width: 48px !important;
        height: 48px !important;
        min-width: 48px !important;
        min-height: 48px !important;
      }
    }
  }
  @media (max-width: $mobileUnsupportedWidth) {
    padding-bottom: 72px;
    &__title {
      font-size: 24px;
    }
    &__row {
      place-content: center;
      display: flex;
      flex-direction: column;
    }

    &__col {
      &__icon {
        margin-bottom: 20px;
        display: inline-flex;
        width: 48px !important;
        height: 48px !important;
        min-width: 48px !important;
        min-height: 48px !important;
      }
    }
  }

  @media screen and (min-width: $tabletLandscapeMinWidth) and (max-width: $tabletLandscapeMaxWidth) {
    &__row {
      place-content: space-between;
    }

    &__col {
      height: 380px;
      text-align: center;
      border-radius: 20px;
      padding: 80px 43px;

      &__icon {
        margin-bottom: 32px;
        display: inline-flex;
      }

      &__caption {
        margin-top: 16px;
        font-weight: 500;
      }
    }
  }

  @media screen and (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
    &__title {
      max-width: 740px;
      padding-top: 80px;
      font-size: 48px;
      margin: 0px auto 60px;
    }

    &__row {
      place-content: center;
      display: flex;
      flex-direction: column;
    }

    &__col {
      max-width: 480px;
      margin: 0px auto 24px;

      &__icon {
        margin-bottom: 28px;
        display: inline-flex;
        width: 56px !important;
        height: 56px !important;
        min-width: 56px !important;
        min-height: 56px !important;
      }
      &_icon span > svg {
        width: 10px !important ;
      }

      &__caption {
        margin-top: 16px;
      }
    }
  }
}
