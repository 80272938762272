@import "src/assets/styles/customs/variables";

.header-mobile {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: $defaultBackground;
  position: fixed;
  top: 0;
  z-index: 3;
  @media (max-width: $mobileUnsupportedWidth) {
    min-width: 360px;
    position: relative;
  }
  @media screen and (max-width: $mobileMinWidth) {
    &__row {
      flex-wrap: nowrap;
    }
  }

  [data-theme="hc"] & {
    border-bottom: 1px solid $grayF7;
  }

  &.underlined {
    border-bottom: 1px solid $line;
  }

  &__menu {
    display: flex;
    align-items: center;
    flex: 0 0 !important;

    &__link {
      cursor: pointer;
      display: flex;

      & svg {
        fill: $linkTextColor;
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 !important;
    & > a {
      height: 16px;
      color: $textColor;
    }
    &__container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    @media screen and (max-width: $mobileMinWidth) {
      &__container {
        gap: 0;
        & svg {
          width: 104px;
        }
        &__flag {
          width: 32px;
          height: 18px;
        }
      }
    }
  }

  &__profile {
    display: flex;
    justify-content: right;
    align-items: center;
    flex: 0 0 !important;

    &__link {
      cursor: pointer;
      display: flex;

      img {
        border-radius: 50%;
      }
      svg :first-child {
        stroke: $linkTextColor;
      }
      svg :last-child {
        fill: $linkTextColor;
      }
    }

    [dir="rtl"] & {
      justify-content: left;
    }
  }

  .profile-placeholder {
    cursor: pointer;
    color: $textColor;
  }
}
