@import "src/assets/styles/customs/variables";
.dialog {
  .alert-content {
    @media (max-width: $mobileMaxWidth) {
      top: 0 !important;
    }
  }
  .alert {
    max-width: 560px !important;

    @media (max-width: $mobileMaxWidth) {
      max-width: 312px;
    }
    @media (max-width: $mobileUnsupportedWidth) {
      width: 312px !important;
    }
  }
  .alert-header__title {
    font-family: $sharpSansFont;
  }
  .alert-header__close__btn {
    display: none !important;
  }

  .alert-footer {
    .button-container:has(:focus-visible) {
      outline: 2px solid $point700;
    }

    @media (max-width: $mobileMaxWidth) {
      display: flex !important;
      margin-top: 24px !important;
      flex-direction: column-reverse;
      .button-container {
        padding: 0 !important;

        &:first-child {
          margin-top: 8px;
        }
      }
    }
  }

  &__close {
    align-self: baseline;
    display: flex;
    justify-content: right;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;

    &:focus-visible {
      outline: 2px solid $point700;
    }

    & .svg {
      margin-left: 4px;
      min-width: 12px;
      min-height: 12px;

      [dir="rtl"] & {
        margin-left: 0;
        margin-right: 4px;
      }
    }

    & .svg svg {
      fill: $grayAa;
    }

    &__text {
      color: $euCloseText;
      display: flex;
      align-items: center;
    }

    @media (max-width: $mobileMaxWidth) {
      padding: 0 !important;
    }
  }
  &__close-in-body {
    align-self: baseline;
    display: flex;
    justify-content: right;
    flex: 1;
    cursor: pointer;
    margin-bottom: 32px;

    & .svg {
      margin-left: 4px;
      min-width: 12px;
      min-height: 12px;

      [dir="rtl"] & {
        margin-right: 4px;
        margin-left: 0;
      }
    }

    & .svg svg {
      fill: $grayAa;
    }

    &__text {
      color: $grayAa;
      display: flex;
      align-items: center;
    }

    @media (min-width: $tabletPortraitMinWidth) {
      display: none;
    }
  }

  &__text {
    margin: 35px 0 32px;
    padding: 24px 24px 32px;
    background-color: $background2;
    border-radius: 12px;
    text-align: center;

    @media (max-width: $mobileMaxWidth) {
      margin: 24px 0 12px;
    }

    &__link {
      color: $point;
      font-weight: 700;
      color: $point700;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  & .button-container {
    display: flex;
    justify-content: center;
  }
  & .button-container .dialog-ok-button {
    width: 136px;
    height: 40px;
    padding: 10px 24px;
  }
}
