@import "src/assets/styles/customs/variables";

.tooltip {
  padding: 12px 40px 12px 16px;
  background-color: $tooltipBackground;
  border-radius: 20px;

  [dir="rtl"] & {
    padding: 12px 16px 12px 40px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    display: flex;

    [dir="rtl"] & {
      left: 16px;
      right: initial;
    }

    .svg {
      color: $linkTextColor;
    }
  }
}
