@import "src/assets/styles/customs/variables";

.privacy-policy {
  [data-theme="hc"] & {
    background-color: $gray31;
  }

  &__title-section {
    padding-top: 70px;
    text-align: center;

    @media (max-width: $mobileMaxWidth) {
      padding-top: 40px;
    }

    &__title {
      margin-top: 12px;
    }

    &__buttons {
      margin-top: 26px;

      [data-theme="hc"] & {
        a > div,
        span {
          color: $linkTextColor;
        }

        span {
          color: $linkTextColor;
        }

        g[data-name="Path 18825"] path {
          stroke: currentColor !important;
        }

        g[data-name="Ellipse 2060"] {
          fill: $gray31 !important;
        }
      }

      @media (max-width: $mobileMaxWidth) {
        & .svg {
          width: 16px !important;
          height: 16px !important;
        }
        & > a {
          border-left: 1px solid $line;

          &:first-child {
            border-left: none;
          }
        }
      }

      & .link--text {
        margin: 0 16.5px;

        @media (max-width: $mobileMaxWidth) {
          margin: 0;
          padding: 0 8px;
        }
      }

      &__version-history span {
        color: $linkTextColor;
      }

      &__language span {
        color: $linkTextColor;
      }

      &__download {
        display: inline-flex;
        margin: 0 16.5px;
        align-items: center;

        @media (max-width: $mobileMaxWidth) {
          margin: 0;
          padding: 0 8px;
        }

        &__icon {
          margin-right: 8px;

          [dir="rtl"] & {
            margin-right: 0;
            margin-left: 8px;
          }
        }
      }
    }
  }

  iframe {
    border: none;
    margin-top: 61px;

    [data-theme="hc"] & {
      * {
        color: $textColor;
      }
    }
  }
}
