@import "src/assets/styles/customs/variables";

.request-details {
  .modal-footer:has(:focus-visible) {
    outline: 2px solid $point700;
  }

  #error-status {
    color: $negative !important;
  }
  .modal-body {
    height: 100% !important;
    margin: 32px 0;
    padding: 0 !important;
  }

  @media (max-width: $mobileMaxWidth) {
    position: fixed;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 3;

    .modal-body {
      height: auto !important;
      flex: 1;

      [dir="rtl"] & {
        margin-left: 0 !important;
        margin-right: -24px !important;
      }
    }

    &__headings {
      padding: 0 24px;
    }

    .modal-content {
      position: relative !important;
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__loading {
    margin: auto;
    display: block;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }

  &__steps {
    display: flex;
    align-items: center;
    justify-content: center;

    &__step {
      width: 10px;
      height: 10px;
      display: flex;
      background-color: $grayDd;
      border-radius: 50%;
      margin: 0 6px;
      font-weight: bold;

      &.active {
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        background-color: $point700Bea;
        color: $white;
        font-family: $sharpSansFont !important;
      }

      &.prev {
        background-color: $point700Bea;
      }

      [data-theme="hc"] & {
        &.active,
        &.prev {
          background-color: $textColor;
          color: $gray31;
        }
      }
    }
  }

  &__icon-main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0;
  }

  &__text-section {
    text-align: center;

    &__status {
      color: $point700Bea;
      margin: 24px 0 8px;
      font-weight: 700;

      [data-theme="hc"] & {
        color: $textColor;
      }
    }

    &__subtitle {
      margin: 12px 0 16px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;

    & > span {
      margin-right: 12px;

      &:last-child {
        margin-right: 0px;
      }

      &:has(:focus-visible) {
        outline: 2px solid $point700;
      }
    }

    &__single {
      margin-left: 0 !important;
    }

    @media (max-width: $mobileMaxWidth) {
      flex-direction: column;

      & .button:last-child {
        margin: 12px 0 0;
      }

      & span {
        margin: 0;
        align-self: center;
      }
    }
  }

  &__services-list {
    background-color: $grayF7;
    border-radius: 12px;
    margin-top: 24px;
    padding: 24px 0;

    [data-theme="hc"] & {
      background-color: $gray4a;
    }

    @media (max-width: $mobileMaxWidth) {
      border-radius: 0;
    }

    & .value {
      font-family: $sharpSansFont;
      font-weight: 500;
      text-align: right;

      [dir="rtl"] & {
        text-align: left;
      }
    }

    .services .col {
      margin: 12px 0;

      &.no-data {
        display: flex;
        align-items: center;
        justify-content: right;

        [dir="rtl"] & {
          justify-content: left;
        }

        @media (max-width: $mobileMaxWidth) {
          justify-content: left;
          padding-left: 52px;

          [dir="rtl"] & {
            padding-right: 52px;
            margin-left: 0;
            justify-content: right;
          }
        }
      }
    }

    &__service {
      display: flex;
      align-items: center;

      & > img {
        margin-right: 12px;
        border-radius: 100%;

        [dir="rtl"] & {
          margin-right: 0;
          margin-left: 12px;
        }
      }

      & .service-name {
        font-weight: bold;
        font-family: $sharpSansFont;
      }
    }

    &__files {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 16px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      & .file-label {
        margin-left: 24px;

        @media (max-width: $mobileMaxWidth) {
          margin-left: 52px;
        }

        [dir="rtl"] & {
          margin-left: 0;
          margin-right: 24px;

          @media (max-width: $mobileMaxWidth) {
            margin-left: 0;
            margin-right: 52px;
          }
        }
      }

      & .file-size {
        flex: 1;
        text-align: right;

        [dir="rtl"] & {
          text-align: left;
        }
      }

      & .download-icon {
        margin-left: 16px;
        cursor: pointer;

        [dir="rtl"] & {
          margin-left: 0;
          margin-right: 16px;
        }

        &:has(:focus-visible) {
          outline: 2px solid $linkTextColor2;
        }
      }
    }
  }

  hr {
    margin: 16px 0 6px;
    border-color: $line;
  }

  .grid-spacer {
    height: 20px;
    width: 100%;
  }

  .btn-close {
    width: 100%;
  }
}

.request-details-alert__pwd-text {
  margin-top: 33px !important;
  margin-bottom: 16px !important;
}

.request-details-alert__pwd-subtext {
  margin: 32px 0px !important;
}

.request-details-alert__pwd-email {
  margin-top: 16px;
  margin-bottom: 16px;
  color: $linkTextColor2;
}

.request-details-alert {
  .button-container:has(:focus-visible) {
    outline: 2px solid $point700;
  }
}
