@import "src/assets/styles/customs/variables";

.history-filter-container {
  width: 100%;
  padding: 0 24px;
  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: $mobileMaxWidth) {
    padding: 0px !important;
    margin: 0 !important;
  }
}

.mydata-personal-info {
  margin: 40px 0;
  @media (max-width: $mobileUnsupportedWidth) {
    &__filter-section {
      width: 100%;
    }

    &__filter-status {
      width: 100%;
    }
  }
  @media (max-width: $mobileMaxWidth) {
    &__filter-section {
      height: 50px;
      overflow: hidden;
      margin-bottom: 24px;
    }

    &__filter-status {
      height: 21px;
      overflow: hidden;
      margin-bottom: 24px;
    }
  }

  & .mydata {
    &__title-section {
      font-family: $sharpSansFont;
      margin-top: 80px;
      @media (max-width: $mobileMaxWidth) {
        margin-top: 40px;
      }

      &__sub {
        font-family: $mainFont;
        font-weight: 500;
        margin-bottom: 40px;
      }
    }
  }

  .tab--container {
    align-content: center;
    justify-content: center;

    @media (max-width: $mobileMaxWidth) {
      padding-left: 24px;
      justify-content: left;
      cursor: grab;
      max-width: 110%;

      [dir="rtl"] & {
        justify-content: right;
        padding-left: 0;
        padding-right: 24px;
      }
    }
  }

  &__service-group {
    margin-bottom: 31px;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    & .tab.tab--1depth {
      @media (max-width: $mobileMaxWidth) {
        white-space: nowrap;
        height: 48px;
      }

      & .tab__content > span {
        font-family: $mainFont;
        line-height: 24px;
        font-weight: 500;

        @media (max-width: $mobileMaxWidth) {
          font-size: 16px;
          line-height: 21px;
        }
      }

      &--selected {
        & .tab__content > span {
          font-weight: bold;
        }
      }
    }

    @media (max-width: $mobileMaxWidth) {
      margin-bottom: 24px;
      display: flex;
      overflow-x: scroll;
      padding-bottom: 17px;
    }
  }

  &__filter-group {
    @media (max-width: $mobileMaxWidth) {
      overflow-x: scroll;
      & .tab {
        &.tab--2depth {
          width: fit-content;
          margin: 0;
          text-align: center;

          & .tab__content {
            & > span {
              font-size: 16px;
              line-height: 21px;

              &.tab--2depth__count {
                line-height: 23.9px;
              }
            }
          }
        }

        &.tab--2depth--selected {
          & .tab__content {
            border-bottom: none;
            & > span {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &__services-container {
    width: 100%;
    border-radius: 20px;
    padding: 40px;
    background-color: $background2;
    margin: 32px 0;

    @media (max-width: $mobileMaxWidth) {
      padding: 42px 24px;

      border-radius: 0;
    }
    @media (max-width: $mobileUnsupportedWidth) {
      width: 302px;
    }
    > ul {
      padding-left: 0;
      @media (max-width: $mobileMaxWidth) {
        margin-top: 0;
      }
    }

    > ul > li {
      @media (max-width: $mobileMaxWidth) {
        padding: 0px 0px 44px 24px;

        [dir="rtl"] & {
          padding: 0px 24px 44px 0;
        }
      }

      padding: 0px 0px 44px 32px;
      margin-bottom: 8px;
      position: relative;
      list-style: none;

      [dir="rtl"] & {
        padding: 0px 32px 44px 0;
      }

      &:before {
        content: " ";
        width: 8px;
        height: 8px;
        background-color: $grayBb;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 3px;

        [dir="rtl"] & {
          left: initial;
          right: 0;
        }
      }

      &:after {
        content: " ";
        width: 1px;
        height: calc(100% - 19px);
        left: 3.5px;
        top: 19px;
        position: absolute;
        background-color: $grayDd;

        [dir="rtl"] & {
          left: initial;
          right: 3.5px;
        }
      }
    }

    &__timestamp {
      margin-top: 5px;
      font-family: $sharpSansFont;
      font-weight: 500;
      color: $gray75;
      line-height: 17px;

      @media (max-width: $mobileMaxWidth) {
        line-height: 21.3px;
      }
    }

    &__title {
      margin: 8px 0 0 0;
      display: flex;
      align-items: center;

      & .title3 {
        font-size: 18px;
        width: fit-content;
      }

      @media (max-width: $mobileMaxWidth) {
        align-items: flex-start;
        margin: 6px 0 0 0;
      }

      &__status {
        line-height: 24px;
        margin-left: 8px;
        color: $point;
        background-color: #f5f7fe;
        padding: 4px 10px;
        border-radius: 12px;

        &.status-W {
          background-color: $green100;
          color: $green800;
        }

        &.status-T {
          background-color: $purple50;
          color: $purple700;
        }

        &.status-E {
          background-color: $grayEe;
          color: $gray75;
        }

        &.status-S {
          background-color: #feebef;
          color: $negative;
        }
      }
    }

    &__list {
      background-color: $white;
      margin-top: 18px;
      padding: 8px 24px;
      border-radius: 12px;
      border: 1px solid $drawerLine;
      position: relative;

      &:after {
        @media (max-width: $mobileMaxWidth) {
          visibility: hidden;
        }

        content: " ";
        height: 16px;
        width: 16px;
        background: url(#{$iconChevronRight});
        position: absolute;
        top: 44%;
        right: 24px;
      }
      & .col {
        display: flex;
        align-items: center;
        margin: 8px 0;

        &.more-services {
          & > img {
            margin-left: -12px;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      & .service-icon {
        border-radius: 50%;
      }

      & .service-name {
        font-family: $sharpSansFont;
        margin-left: 12px;
        color: $gray31;
        font-size: 16px;
        line-height: 24px;
      }

      &.faded {
        background-color: $grayEe;
        & .col {
          opacity: 0.25;
        }
      }
    }

    &__view-more {
      text-align: center;
      margin-top: 40px;

      &__button {
        background-color: initial;
        border: solid 1px $linkTextColor;
        padding: 10px 24px;

        span {
          font-size: 14px;
          font-weight: bold;
          line-height: 1.29;
          color: $linkTextColor;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
          border-color: $gray55;

          span {
            color: $gray55;
          }

          [data-theme="hc"] & {
            background-color: initial;
            border: solid 1px $textColor;

            span {
              color: $textColor;
            }
          }
        }
      }
    }
  }
}
