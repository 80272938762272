@import "src/assets/styles/customs/variables";

.global-rights {
  @media (max-width: $mobileMinWidth) {
    .modal {
      margin: 0 24px !important;
    }
  }
}

.USA {
  background-color: #f9627a;
}

.China {
  background-color: #f9e60e;
}

.EU {
  background-color: #69b5f9;
}

.Türkiye {
  background-color: #c67ff6;
}
.Brazil {
  background-color: #06b190;
}

.global-rights {
  svg #ic_map_mena path,
  svg #ic_map_asia path,
  svg #ic_map_eu path,
  svg #ic_map_north_america path,
  svg #ic_map_latin_america path {
    fill: $gpsMapBackground;
    cursor: pointer;
  }

  svg #ic_map_asia:hover path,
  svg #ic_map_asia:has(:focus-visible) path,
  svg #ic_map_mena:hover path,
  svg #ic_map_mena:has(:focus-visible) path,
  svg #ic_map_eu:hover path,
  svg #ic_map_eu:has(:focus-visible) path,
  svg #ic_map_north_america:hover path,
  svg #ic_map_north_america:has(:focus-visible) path,
  svg #ic_map_latin_america:hover path,
  svg #ic_map_latin_america:has(:focus-visible) path {
    fill: #84bcff;
    cursor: pointer;
  }

  // svg .inactive path {
  //   fill: #ddd !important;
  // }

  svg .ic_map_country_name {
    fill: $gpsCountryName !important;
    html[data-theme="hc"]:hover {
      fill: black;
    }
  }

  svg .ic_map_country_icon {
    fill: $gpsCountryIcon !important;
  }

  [data-theme="hc"] & {
    background-color: $gray3c;
  }

  .helptips-wrapper {
    display: none;
  }

  &__title-section {
    padding-top: 80px;
    text-align: center;

    @media (max-width: $mobileMaxWidth) {
      padding-top: 40px;
    }

    &__sub-title {
      margin-top: 12px;
      color: $gray31;

      [data-theme="hc"] & {
        color: $textColor;
      }
    }
  }

  .static-modal-wrapper .modal-content .modal {
    padding-bottom: 16px;

    & .modal-body {
      margin-bottom: 0;
    }
  }

  &__link-container {
    padding: 30px 23.1px 30px 16px;
    background-color: $background2;
    border-top: 1px solid $gpsCountryModalLine;
    border-bottom: 1px solid $gpsCountryModalLine;

    @media (max-width: $mobileMaxWidth) {
      padding: 24px;
    }

    &__checkbox {
      margin-bottom: 22px;
      align-items: center;
      display: flex;

      [dir="rtl"] & .svg {
        transform: rotate(180deg);
      }
    }

    .checkboxGoIcon {
      width: auto !important;
      height: 16px !important;
      min-width: 0 !important;
      min-height: 0 !important;
    }

    .checkbox--withoutSelectBg > span {
      font-weight: normal !important;
      color: $linkTextColor6;

      &.typography {
        flex: 1 0;
      }
    }
  }

  &__selected-region .row {
    align-items: center;
    margin-top: 24px;
  }

  @media (min-width: $tabletPortraitMinWidth) {
    .global-site__container {
      margin-top: 40px;
      margin-bottom: -114px;
      z-index: 1;
    }
  }

  @media (max-width: $mobileMaxWidth) {
    .global-site__container {
      margin-top: 32px;
      margin-bottom: -32px;
    }
  }

  &__map-container {
    padding: 80px 0;
    background-color: $point50;
    margin-top: 77px;
    position: relative;

    [data-theme="hc"] & {
      background-color: $gray3c;
    }

    @media (max-width: $mobileMaxWidth) {
      margin-top: 32px;
      padding: 0;
    }

    & > .container {
      position: relative;

      @media (max-width: $mobileMaxWidth) {
        padding: 24px;
      }
    }

    &__map {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 1218px;
      height: auto !important;
      width: auto !important;
      min-height: 0 !important;
      margin: 0 auto;

      .link--text--lg {
        margin-bottom: 48px;

        & > span {
          font-size: 28px;
          & svg {
            transform: rotate(180deg);
          }

          [dir="rtl"] & svg {
            transform: rotate(360deg);
          }
        }
      }

      &__selected {
        margin-bottom: 90px;
        width: auto !important;
        height: auto !important;
        animation: fadein 2s;

        & path[fill="#313131"] {
          fill: $subTextColor;
        }
        & path[fill="#84BCFF"] {
          fill: $gpsSelectedRegionBackground;
        }
      }

      @keyframes fadein {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      tspan {
        font-family: $sharpSansFont !important;
        color: $subTextColor;
      }

      @media (max-width: $mobileMaxWidth) {
        padding: 24px;
        margin-left: -24px !important;
        margin-right: -24px !important;
        text-align: left;

        & .left-icon {
          display: none;
        }
      }
    }

    &__title {
      text-align: center;

      &__main {
        font-family: $sharpSansFont !important;
      }

      &__sub {
        margin-top: 12px;
        color: $gray31;
        font-size: 24px;
        [data-theme="hc"] & {
          color: $textColor;
        }
        &:last-child {
          margin-bottom: 60px;
        }

        & > span,
        & > a {
          color: $point700;
          text-decoration: underline;
          font-weight: 700;
          line-height: 1.25;
          cursor: pointer;

          [data-theme="hc"] & {
            color: $linkTextColor;
          }
        }
        @media (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.25;
        }

        @media (max-width: $mobileUnsupportedWidth) {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.25;
        }
      }
    }
  }

  &__countries {
    margin-top: 32px;
    padding-bottom: 200px;

    @media (max-width: $mobileMaxWidth) {
      padding-bottom: 80px;
    }

    .row {
      justify-content: center;
      @media (min-width: $tabletPortraitMinWidth) {
        max-width: 896px;
        margin: 0 auto;
      }
    }

    .col {
      margin-bottom: 24px;
      max-width: 448px;
    }

    &__item {
      padding: 32px;
      border-radius: 12px;
      border: 1px solid $line;
      display: flex;
      min-height: 130px;

      & > div {
        & .svg {
          margin-right: 4px;
        }
        width: 100%;
        display: flex;
      }

      &__region {
        & .typography {
          color: $subTextColor;
        }
        @media (min-width: $desktopMinWidth) {
          padding-right: 40px !important;

          [dir="rtl"] & {
            padding-right: 0 !important;
            padding-left: 40px !important;
          }
        }

        @media (max-width: $tabletPortraitMaxWidth) {
          padding-right: 32px !important;
          [dir="rtl"] & {
            padding-right: 0 !important;
            padding-left: 32px !important;
          }
        }

        @media (max-width: $mobileMaxWidth) {
          .typography {
            font-size: 18px;
          }
        }
        @media (max-width: $mobileUnsupportedWidth) {
          .typography {
            font-size: 18px;
          }
        }
      }

      @media (max-width: $mobileMaxWidth) {
        display: block;
        padding: 24px;
        min-height: 0;

        &__region {
          margin-bottom: 16px;
          width: 100%;
        }
      }

      &__list {
        display: flex !important;
        flex-direction: column;

        .link {
          width: 100%;
        }
        &__container {
          display: flex;
          flex-direction: row;
        }
        &__pin {
          min-width: 20px;
          height: 20px;
          border-radius: 100%;
          margin-right: 8px;

          [dir="rtl"] & {
            margin-right: 0;
            margin-left: 8px;
          }
        }
        &__link {
          font-weight: 400;
          font-size: 18px;
          color: $gray31 !important;
          margin-bottom: 8px;
          width: 100%;

          [dir="rtl"] & {
            svg {
              transform: rotate(180deg);
            }
          }

          [data-theme="hc"] & {
            color: $linkTextColor !important;

            svg {
              fill: $linkTextColor;
            }
          }

          @media (max-width: $mobileMaxWidth) {
            font-size: 16px;
            font-weight: 400 !important;
          }
        }
      }
    }
  }

  &__modal {
    .col {
      margin-bottom: 22px;
      display: flex;

      label {
        align-items: center;

        & > span {
          color: $linkTextColor6;
        }
      }
    }

    .accordion-item__content {
      padding: 30px 23.1px 30px 16px;
    }

    .checkbox {
      justify-content: space-between;
    }
  }

  @media (max-width: $mobileMaxWidth) {
    .static-modal-wrapper {
      margin: 0 -24px;

      .modal {
        border-radius: 0 !important;

        & .modal-body {
          max-height: initial !important;
          margin: 29px -24px !important;
          overflow: initial !important;
        }
      }
    }
  }
}
.gpsMapMobile {
  svg #asia path,
  svg #africa path,
  svg #europe path,
  svg #north_america path,
  svg #latin_america path,
  svg #middle_east path {
    fill: $gpsMapBackground;
    cursor: pointer;
  }
  svg .country_name {
    fill: $gpsCountryName !important;
  }

  svg .country_icon {
    fill: $gpsCountryIcon !important;
  }
}
