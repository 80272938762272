@import "src/assets/styles/customs/variables";

.manage-apps {
  background-color: $background7;
  margin-top: 100px;
  padding: 100px 0;

  @media (max-width: $mobileUnsupportedWidth) {
    overflow-x: auto;
  }

  @media (max-width: $mobileMaxWidth) {
    margin: 0;
    padding: 48px 0;
  }

  &__loading {
    margin: 0 auto;
    display: block;
  }

  &__title-section {
    text-align: center;

    @media (min-width: $tabletPortraitMinWidth) {
      &__selection {
        .tab--2depth {
          padding-top: 9px !important;
          padding-bottom: 9px !important;
        }
      }
    }

    @media (max-width: $mobileMaxWidth) {
      .typography {
        font-size: 20px;
        line-height: 26px;
      }

      &__selection {
        height: 25px;
        overflow: hidden;
        margin: 24px 0 44px;
        justify-content: center;

        & .manage-apps__tabs {
          overflow-x: scroll;
          margin: 0;
          justify-content: left;
          height: 30px;

          & .tab--2depth {
            width: 100%;

            & .tab__content {
              & > span {
                white-space: nowrap;
                font-size: 16px;
                line-height: 24px;
              }
            }

            &.tab--2depth--selected {
              & .tab__content {
                border-bottom: none;
                & > span {
                  border-bottom: solid 2px $blk;
                }
              }
            }
          }
        }
      }
    }
  }

  &__options-all {
    display: flex;

    & > button {
      padding: 0;
      background-color: transparent;
      border: none;

      &:focus-visible {
        outline: 2px solid $point700;
      }
    }

    .all-text {
      font-family: "SamsungSharpSans";
      display: flex;
      align-items: center;
      line-height: 1.33;
      color: $subTextColor;
    }

    .count {
      font-weight: bold;
      margin-left: 8px;

      [dir="rtl"] & {
        margin-left: 0;
        margin-right: 8px;
      }

      @media (max-width: $mobileMaxWidth) {
        margin-left: 4px;
      }
    }
  }

  hr {
    margin: 13px 0 0;
    border-color: $drawerLine;
  }

  &__services {
    &__checkbox > button {
      padding: 0;
      background-color: transparent;
      border: none;

      &:focus-visible {
        outline: 2px solid $point700;
      }
    }

    &__info {
      background-color: $background5;
      margin-top: 8px;
      border-radius: 20px;
      text-align: center;
      padding: 0 32px 28px;

      &__icon {
        border-radius: 50%;
        margin: -32px auto 0;
      }

      &__name {
        font-size: 18px;
        font-weight: bold;
        font-family: $sharpSansFont;
        margin-top: 22px;
        align-items: center;
        word-break: break-word;
        svg {
          [dir="rtl"] & {
            transform: rotate(180deg);
          }
        }

        .right-icon {
          margin-left: 4px !important;
          margin-right: 0 !important;

          [dir="rtl"] & {
            margin-left: 0 !important;
            margin-right: 4px !important;
          }
        }
      }

      &__text {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__busy {
        color: $linkTextColor2;
        font-weight: bold;
      }

      &__joined {
        font-size: 14px;
        margin-top: 12px;
        display: none;
      }
    }

    hr {
      margin: 24px 0;
    }

    .col {
      margin-top: 24px;
    }
  }

  &__tabs {
    margin: 24px 0 44px;
    justify-content: center;
  }
}

.service-info-modal {
  &__icon {
    border-radius: 100%;
  }

  .modal-content {
    position: fixed;
  }

  .modal-footer:has(:focus-visible) {
    outline: 2px solid $point700;
  }

  &__description {
    background-color: $background2;
    padding: 24px;
    border-radius: 12px;
    color: $textColor;

    &__join-date {
      margin-bottom: 12px;
      display: none;
    }

    a {
      color: $linkTextColor2;
    }
  }

  .modal-title {
    margin-left: 12px;

    [dir="rtl"] & {
      margin-left: 0;
      margin-right: 12px;
    }
  }

  .modal-footer {
    .button {
      width: 100%;
    }
  }

  @media (max-width: $mobileMaxWidth) {
    .modal {
      margin: 0 24px !important;
    }
  }
}
