@import "src/assets/styles/customs/variables";

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $dim;
  z-index: $overlayZi;
  display: none;

  &.active {
    display: block;
  }
}
