@import "src/assets/styles/customs/variables";
.mydata-summary {
  padding: 60px 40px 32px;
  border-radius: 20px;
  background-color: $background6;
  margin-top: 41px;
  @media (max-width: $mobileUnsupportedWidth) {
    overflow-x: auto;
    width: 360px;
  }
  &.china {
    margin-bottom: 109px;
  }

  @media (max-width: $mobileMaxWidth) {
    margin: 34px -24px 0 -24px;
    background-color: initial;
    padding: 0;

    &.china {
      margin-bottom: 0;
    }
  }

  &__counts {
    display: flex;
    justify-content: center;

    @media (max-width: $mobileMaxWidth) {
      background-color: $background6;
      padding: 32px 24px 28px;
      justify-content: space-around;
    }

    &__item {
      & .svg {
        margin: 0 auto;
      }

      &__text {
        margin: 12px 0;
        text-align: center;

        @media (max-width: $mobileMaxWidth) {
          font-size: 15px;
          margin-bottom: 14px;
          font-weight: 400;
          white-space: nowrap;
        }
      }

      &__count {
        text-align: center;
        color: $linkTextColor3;
        font-family: $sharpSansFont !important;

        @media (max-width: $mobileMaxWidth) {
          font-size: 28px;
          line-height: 0.86;
        }
      }
    }
  }

  &__services-container {
    margin-top: 40px;
    border-radius: 20px;
    padding: 40px;
    background-color: $background5;

    @media (max-width: $mobileMaxWidth) {
      background: none;
    }

    &.empty_services {
      padding: 100px 40px 76px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .typography {
        margin-top: 32px;
      }

      @media (max-width: $mobileMaxWidth) {
        padding: 0px 40px 32px;

        .typography {
          margin-top: 16px;
        }
      }
    }

    &.china {
      padding: 100px 40px 76px;
      text-align: center;

      @media (max-width: $mobileMaxWidth) {
        padding-top: 0;
        padding-bottom: 112px;
      }

      .svg {
        margin: auto;
      }

      .typography {
        margin-top: 32px;
      }

      a {
        color: $point700;
        font-weight: 500;
        text-decoration: underline;
      }
    }

    @media (max-width: $mobileMaxWidth) {
      margin-top: 32px;
      padding: 0 24px;
    }
  }

  .spacer {
    width: 1px;
    margin: 0 100px;
    background-color: $spacer;
    opacity: 0.2;

    @media (max-width: $mobileMaxWidth) {
      margin: 0;
    }
  }

  .mydata-summary__services-container {
    & > ul {
      margin: 0;
      padding: 0;
      list-style: none;

      & > li {
        position: relative;
        padding: 0 0 44px 32px;
        margin-top: 8px;

        [dir="rtl"] & {
          padding: 0 32px 44px 0;
        }

        @media (max-width: $mobileMaxWidth) {
          padding-left: 31px;

          [dir="rtl"] & {
            padding-left: 0;
            padding-right: 31px;
          }
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }

        &:before {
          content: " ";
          width: 8px;
          height: 8px;
          background-color: $bullet;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 3px;

          [dir="rtl"] & {
            left: initial;
            right: 0;
          }
        }

        &:after {
          content: " ";
          width: 1px;
          height: calc(100% - 19px);
          left: 3.5px;
          top: 19px;
          position: absolute;
          background-color: $lineBlue;

          [dir="rtl"] & {
            left: initial;
            right: 3.5px;
          }
        }
      }
    }

    &__timestamp {
      font-family: $sharpSansFont;
      color: $gray75;
    }

    &__title {
      margin-top: 8px;
      display: flex;
      align-items: center;

      @media (max-width: $mobileMaxWidth) {
        margin-top: 6px;

        & .title3 {
          font-size: 18;
          line-height: 1.33;
          display: flex;
          flex: 1;
        }
      }

      &__status {
        margin-left: 8px;
        color: $point;
        background-color: $point50;
        padding: 0 10px;
        border-radius: 12px;
        line-height: 2;

        [dir="rtl"] & {
          margin-left: 0;
          margin-right: 8px;
        }

        &.status-W {
          background-color: $green100;
          color: $green800;
        }

        &.status-T {
          background-color: $purple50;
          color: $purple700;
        }
      }
    }

    &__list {
      margin-top: 18px;
      padding: 8px 24px;
      border-radius: 12px;
      border: 1px solid $line;
      cursor: pointer;
      @media (max-width: $mobileMaxWidth) {
        margin-top: 16px;
      }

      & .col {
        display: flex;
        align-items: center;
        margin: 8px 0;

        &.more-services {
          & > img {
            margin-left: -12px;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      & .service-icon {
        border-radius: 50%;
      }

      & .service-name {
        font-size: 16px;
        margin-left: 12px;
        font-family: $sharpSansFont;
      }
    }
  }

  .mydata-summary__services-viewall-container {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    & > span:has(:focus-visible) {
      outline: 2px solid $point700;
    }

    @media (max-width: $mobileMaxWidth) {
      margin: 40px 0;
    }

    &__button {
      background-color: initial;
      border: solid 1px $linkTextColor;
      padding: 10px 24px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        border-color: $gray55;

        span {
          color: $gray55;
        }

        [data-theme="hc"] & {
          background-color: initial;
          border: solid 1px $textColor;

          span {
            color: $textColor;
          }
        }
      }

      span {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.29;
        color: $linkTextColor;
      }
    }
  }
}
