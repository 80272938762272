@import "src/assets/styles/customs/variables";

.privacy-rights-modal {
  @media (max-width: $mobileMaxWidth) {
    .modal-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 12;
    }
  }

  .modal {
    max-width: 640px !important;

    @media (max-width: $mobileMaxWidth) {
      top: initial !important;
      transform: initial !important;
      border-radius: 0 !important;
      max-height: initial !important;
      min-width: 100vw;
      height: 100dvh !important;
    }
    @media (max-width: $mobileUnsupportedWidth) {
      width: 360px !important;
      margin: 0 !important;
    }
  }

  &__message {
    padding: 16px 110px;
    border-radius: 12px;
    border: solid 1px $line;
    text-align: center;

    @media (max-width: $mobileMaxWidth) {
      padding: 16px 24px;
    }

    & .typography {
      color: $subTextColor;
      margin: 0;
    }
  }

  &__heading {
    text-align: center;
    color: $linkTextColor2;
    margin-top: 40px;
    margin-bottom: 16px;
  }

  &__country {
    margin: 0 auto;
    width: 300px !important;
    height: 200px !important;

    @media (max-width: $mobileMaxWidth) {
      width: 200px !important;
    }

    & path[fill="#84BCFF"],
    path[fill="#84BCF8"] {
      fill: $gpsSelectedRegionBackground !important;
    }
  }

  &__section {
    text-align: center;

    &__title {
      margin: 20px 0 4px;
    }

    &__subtitle {
      margin-bottom: 12px;
    }
  }

  .modal-body {
    overflow-x: hidden !important;
    margin-bottom: 64px !important;
  }

  .modal-footer {
    .button {
      width: 100%;
      height: 40px;
      padding: 0;
    }
  }
}

.rights-carousel {
  &__controls {
    @media (max-height: 350px) {
      display: none;
    }

    &__button {
      cursor: pointer;
      position: absolute;
      top: -130px;

      @media (max-width: $mobileMaxWidth) {
        margin: 0;
      }

      & path {
        stroke: $linkTextColor4;
      }

      &--left {
        transform: rotate(-180deg);
      }

      &--right {
        right: 5px;
      }

      &.disabled {
        path {
          stroke: $buttonDisabledBackground !important;
        }
      }
    }

    &.slider-control-bottomcenter {
      position: absolute;
      bottom: 0;
    }
  }

  &__dots {
    top: 40px !important;
    border: 1px solid rgba($blk, 0.1);
    border-radius: 16px;

    .paging-item {
      button {
        padding: 0 6px;
        opacity: 1 !important;

        svg {
          fill: $gray8f;
        }
      }

      &.active {
        svg {
          fill: $blk;
        }
      }
    }

    .paging-dot {
      width: 8px;
      height: 8px;
    }
  }
}
