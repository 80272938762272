html {
  --black: #000000;
  --defaultBg: #fff;
  --background2: #f7f7f7;
  --background3: #f7f7f7;
  --background4: #fff;
  --background5: #fff;
  --background6: #eaf4ff;
  --background7: #f7f7f7;
  --linkTextColor: #000000;
  --linkTextColor2: #006bea;
  --linkTextColor3: #006bea;
  --linkTextColor4: #fff;
  --linkTextColor5: #fff;
  --linkTextColor6: #757575;
  --secondaryLinkTextColor: #757575;
  --textColor: #000000;
  --whiteTextColor: #fff;
  --subTextColor: #313131;
  --timestampColor: #313131;
  --grayTextColor: #757575;
  --tab1depthTextColor: #313131;
  --tab1depthSelectedTextColor: #fff;
  --activeMenuTextColor: #313131;
  --buttonTextColor: #fff;
  --background1: #eaf4ff;
  --buttonDisabledBg: #dddddd;
  --buttonDisabledText: #aaaaaa;
  // --tooltipBackground: #ccd7ff;
  --tooltipBackground: #b7d8ff;
  --checkboxSelected: #7994f9;
  --chevronMore: #aaa;
  --scrollbarTrack: #fff;
  --scrollbarThumb: #c8c8c8;
  --scrollbarThumbHover: #888;
  --lineBlue: #b7d8ff;
  --bullet: #bdccff;
  --borderSwitch: #8f8f8f;
  --switchOnBg: #9db2ff;
  --switchOnThumbBg: #fff;
  --shadow: #fff;
  --arrowColor: #aaa;
  --toastBackground: #006bea;
  --toastCloseIconBackground: #000;
  --toastCloseIconText: #fff;
  --euCloseText: #aaa;
  --helpTipsOutline: #313131;
  --gpsMapBackground: #ddd;
  --gpsCountryIcon: #666;
  --gpsCountryName: #666;
  --gpsSelectedRegionBackground: #84bcff;
  --gpsCountryModalLine: #eee;
  --line: #eaeaea;
  --drawerLine: #eaeaea;
  --carouselDotActive: #fff;
  --carouselDot: #bbb;
  --localSiteHeaderBackground: #000;
  --consignmentTable: #ddd;
}

html[data-theme="hc"] {
  --black: #ffffff;
  --defaultBg: #313131;
  --background2: #4a4a4a;
  --background3: #313131;
  --background4: #000;
  --background5: #4a4a4a;
  --background6: #3c3c3c;
  --background7: #3c3c3c;
  --linkTextColor: #fff01f;
  --linkTextColor2: #fff01f;
  --linkTextColor3: #00dfe6;
  --linkTextColor4: #fff01f;
  --linkTextColor5: #00dfe6;
  --linkTextColor6: #fff01f;
  --secondaryLinkTextColor: #1a1918;
  --textColor: #00dfe6;
  --subTextColor: #00dfe6;
  --whiteTextColor: #00dfe6;
  --timestampColor: #00dfe6;
  --grayTextColor: #00dfe6;
  --tab1depthTextColor: #fff01f;
  --tab1depthSelectedTextColor: #000;
  --activeMenuTextColor: #fff01f;
  --buttonTextColor: #000;
  --background1: #4a4a4a;
  --buttonDisabledBg: #555555;
  --buttonDisabledText: #8f8f8f;
  --tooltipBackground: #20426b;
  --checkboxSelected: #fff01f;
  --chevronMore: #fff01f;
  --scrollbarTrack: transparent;
  --scrollbarThumb: #4a4a4a;
  --scrollbarThumbHover: #444;
  --lineBlue: #f7f7f7;
  --bullet: #f7f7f7;
  --borderSwitch: #fff01f;
  --switchOnBg: #fff01f;
  --switchOnThumbBg: #313131;
  --shadow: #4a4a4a;
  --arrowColor: #fff01f;
  --toastBackground: #20426b;
  --toastCloseIconBackground: #fff01f;
  --toastCloseIconText: #000;
  --euCloseText: #fff01f;
  --helpTipsOutline: #006bea;
  --gpsMapBackground: #4a4a4a;
  --gpsCountryIcon: #fff01f;
  --gpsCountryName: #fff01f;
  --gpsSelectedRegionBackground: #00dfe6;
  --gpsCountryModalLine: #313131;
  --line: #eaeaea;
  --drawerLine: #eaeaea;
  --carouselDotActive: #00dfe6;
  --carouselDot: #fff01f;
  --localSiteHeaderBackground: #000;
  --consignmentTable: #4a4a4a;
}
