@import "src/assets/styles/customs/variables";

div.tab--container {
  display: flex;

  .tab {
    cursor: pointer;
    input[type="radio"] {
      visibility: hidden;
      width: 0;
      height: 0;
      position: absolute;
    }

    .disabled {
      color: $grayDd !important;
      cursor: not-allowed;
    }
    .pointerCursor {
      cursor: pointer;
    }

    button {
      background-color: transparent;
      padding: 0;
      border: none;

      &:focus-visible {
        outline: 2px solid $point700;
      }
    }

    &--1depth {
      width: 350px;
      border: solid 1px $drawerLine;
      background-color: transparent;
      text-align: center;
      padding: min(15px) min(24px);
      .tab__content > span {
        color: $tab1depthTextColor;
        font-size: 18px;
        line-height: 31.9px;
        white-space: nowrap;
      }

      &--selected {
        background-color: $linkTextColor;
        .tab__content > span {
          color: $tab1depthSelectedTextColor;
          white-space: nowrap;
        }

        [data-theme="hc"] & {
          border: none;
        }
      }
    }
    &--2depth {
      margin: 0 8px;
      padding: 0 16px;
      &__count {
        padding-left: 6px;
        font-family: $sharpSansFont !important;
        font-weight: bold !important;
      }
      .tab__content > span {
        font-family: $mainFont;
        font-size: 18px;
        font-weight: 700;
        color: $activeMenuTextColor;
        line-height: 23.9px;
        white-space: nowrap;
      }

      &--selected {
        .tab__content {
          border-bottom: solid 2px $linkTextColor;
          white-space: nowrap;
        }
      }
    }
  }

  label.tab--2depth:first-child {
    margin-left: 0;
  }
  label.tab--2depth:last-child {
    margin-right: 0;
  }
  label:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    [dir="rtl"] & {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  label:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    [dir="rtl"] & {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
}
