@import "src/assets/styles/customs/variables";

.disabled-button {
  cursor: not-allowed;
}

.button {
  //Font
  font-family: $SamsungOneLatin;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;

  //Layout
  width: auto;
  gap: 10px;
  padding: 10px 24px;
  border-radius: 20px;
  border-color: transparent;
  .loading-button__spinner_left {
    margin-right: 4px;
  }
  .loading-button__spinner_right {
    margin-left: 4px;
  }
  .button-text-icon-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  &--primary {
    background-color: $linkTextColor;
    color: $buttonTextColor;

    &:hover {
      background-color: $grayTextColor;
      color: $buttonTextColor;

      > span .svg svg {
        fill: $grayEe;
      }
    }

    &:disabled {
      background-color: $buttonDisabledBackground;
      color: $buttonDisabledTextColor;

      > span .svg svg {
        fill: $grayAa;
      }
    }

    > span .svg svg {
      fill: $white;
    }
  }

  &--secondary {
    background-color: transparent;
    color: $linkTextColor;
    border: solid 1px $linkTextColor;

    &:hover {
      background-color: rgba($linkTextColor, 0.05);
      border-color: $grayTextColor;
      color: $grayTextColor;
    }

    &--icon:hover {
      background-color: $white;
    }

    &:disabled {
      background-color: $buttonDisabledBackground;
      color: $buttonDisabledTextColor;
      border: solid 1px $grayDd;

      > span .svg svg {
        fill: $grayDd;
      }
    }

    > span .svg svg {
      fill: $gray55;
    }
  }

  &--content {
    color: #5579f6;
    border: solid 1px #5579f6;
    background-color: $white;
    padding: 11px 24px;

    &:disabled {
      background-color: $white;
      color: $grayDd;
      border: solid 1px $grayDd;
    }

    > span {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &:not(.disabled-button):hover {
    cursor: pointer;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    > .left-icon {
      margin-right: 8px;

      [dir="rtl"] & {
        margin-left: 8px;
        margin-right: 0;
      }
    }

    > .right-icon {
      margin-left: 8px;

      [dir="rtl"] & {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }
}

.link {
  //Layout
  display: inline-block;

  &--text {
    border-radius: 0;
    width: auto;

    &--underlined {
      > span > span {
        border-bottom: solid 2px $blk;
      }
    }

    &:disabled {
      background-color: $white;
      color: $grayDd;
      border: solid 1px $grayDd;
    }

    > span svg {
      color: $linkTextColor;

      & text {
        fill: $linkTextColor;
      }
    }

    span {
      color: $linkTextColor6;
      display: flex;
      align-items: center;
      font-weight: 700;

      > .left-icon {
        margin-right: 8px;

        [dir="rtl"] & {
          margin-left: 8px;
          margin-right: 0;
        }
      }

      > .right-icon {
        margin-left: 8px;

        [dir="rtl"] & {
          margin-left: 0;
          margin-right: 8px;
        }
      }
    }

    &--sm {
      > span {
        line-height: 18px;

        .right-icon {
          margin-left: 4px;
        }
      }

      &--icon {
        > span {
          line-height: 16px;
        }
      }
    }

    &--lg {
      > span {
        font-size: 16px;
        line-height: 20px;
      }

      &--icon {
        > span {
          line-height: 24px;
        }
      }
    }
  }
}
