@import "src/assets/styles/customs/variables";

.mydata .toast {
  position: relative;
  width: 100%;

  @media (max-width: $mobileMaxWidth) {
    margin-bottom: 52px;
  }
}

.toast {
  cursor: default;
  padding: 25px 40px;
  border-radius: 12px;
  visibility: hidden;
  display: flex;
  align-items: center;
  position: absolute;
  opacity: 0;
  width: calc(100% - 58px);
  z-index: 4;
  flex-direction: row;
  top: 1%;
  max-width: 1392px;

  &:hover {
    pointer-events: none;
  }
  &.show {
    visibility: visible;
    opacity: 1;
    background-color: $toastBackground;
    animation-fill-mode: forwards;
    animation: fadein 2s;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
  &.home {
    top: 81px;
    position: fixed;

    @media (max-width: $mobileMaxWidth) {
      top: 56px;
    }
  }

  @media (max-width: $mobileMaxWidth) {
    width: calc(100% - 48px);
  }

  &__info {
    display: flex;
    align-items: center;
    color: $linkTextColor5;
    flex: 1;
    margin-right: 20px;

    [dir="rtl"] & {
      margin-left: 20px;
      margin-right: 0;
    }

    @media (max-width: $mobileMaxWidth) {
      margin: 0 !important;
    }

    &__link {
      font-size: 16px !important;
      font-weight: 700;
      line-height: 1.33 !important;
      margin-right: 8px;
      color: $white !important;
      text-decoration: underline;

      & > span {
        color: $white !important;
        border-bottom: solid 2px $white !important;
      }
    }
  }

  &__ok {
    text-decoration: underline;
    cursor: pointer;
    margin-right: 8px;
    color: $linkTextColor4;

    & > a {
      color: $linkTextColor4;

      &:focus-visible {
        outline: 2px solid $helpTipsOutline;
      }
    }

    &:hover {
      pointer-events: all;
    }

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &__close {
    color: $linkTextColor4;
    padding: 0;
    background-color: transparent;
    border: none;

    [dir="rtl"] & {
      right: initial;
      left: 24px;
    }

    & span {
      cursor: pointer;
    }

    & svg {
      fill: $white;
      cursor: pointer;
    }

    &:hover {
      pointer-events: all;
    }

    &:focus-visible {
      outline: 2px solid $helpTipsOutline;
    }
  }

  @media (max-width: $mobileMaxWidth) {
    padding: 24px;
    display: block;

    & > .svg {
      margin: 0 auto 12px;
    }

    &__info {
      display: block;
      align-items: initial;
      font-weight: 400;

      &__link {
        margin-top: 3px;
        font-size: 16px !important;
        text-decoration: underline;
        text-decoration-color: $white;

        & > span {
          border-bottom: 0 !important;
        }
      }
    }
    &__ok {
      font-weight: 400;
      margin-top: 16px;
      display: block;
      text-align: right;

      [dir="rtl"] & {
        text-align: left;
      }
    }
    &__close {
      position: absolute !important;
      width: 12px;
      height: 12px;
      top: 12px;
      right: 12px;
    }
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
