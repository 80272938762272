@import "src/assets/styles/customs/variables";

.learn-more {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  text-align: center;
  min-height: calc(100vh - 82px);
  display: flex;
  align-items: center;

  @media (max-width: $mobileMaxWidth) {
    min-height: calc(100vh - 57px);

    & .container {
      padding: 0;
    }
  }
  @media (min-width: $tabletPortraitMinWidth) {
    min-height: calc(100vh - 57px);
  }

  &__title {
    margin-top: 60px;
    margin-bottom: 16px;
    @media (max-width: $mobileMaxWidth) {
      font-size: 24px;
      margin: 16px 36px;
    }
  }

  &__subtitle {
    font-size: 24px;

    @media (max-width: $mobileMaxWidth) {
      font-size: 16px;
      margin-top: 12px;
      margin: 16px;
    }
  }

  &__tabs {
    justify-content: center;
    margin-top: 48px;
    gap: 60px;

    @media (max-width: $tabletPortraitMaxWidth) {
      flex-wrap: wrap;
      margin-top: 12px;
      gap: 16px;
      html[lang="my"] & {
        gap: 10px;
      }
    }

    & .tab.tab--2depth {
      margin: 0;
      padding: 0;
    }

    & .tab__content > span {
      font-size: 24px !important;
      color: $gray75 !important;
      cursor: pointer;
      [data-theme="hc"] & {
        color: $linkTextColor2 !important;
      }
      @media (max-width: $mobileMaxWidth) {
        font-size: 16px !important;
      }
    }

    & .tab--2depth--selected {
      & .tab__content {
        border-bottom: solid 2px $linkTextColor2 !important;
        [data-theme="hc"] & {
          border-bottom: solid 2px $linkTextColor3 !important;
        }

        & > span {
          color: $linkTextColor2 !important;
          cursor: pointer;
          [data-theme="hc"] & {
            color: $linkTextColor3 !important;
          }
        }
      }
    }
  }

  &__map {
    margin-top: 30px;

    & .selected-region {
      pointer-events: bounding-box;
    }

    [data-theme="hc"] & svg > .selected-region > path {
      fill: $linkTextColor3;
    }
    [data-theme="hc"] & svg > path {
      fill: $background2;
    }
    [data-theme="hc"] & svg > .selected-region a > path {
      fill: $linkTextColor;
    }
    @media (max-width: $mobileMaxWidth) {
      margin-top: 40px;

      &.container {
        padding: 0 24px;
      }
    }
  }
  @media screen and (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
    &__title {
      font-size: 42px;
    }
    &__subtitle {
      font-size: 18px;
    }
  }
}
