@import "src/assets/styles/customs/variables";
$minColWidth: 8.33333333%;

.container {
  width: 100%;
  padding: 0 24px;
  max-width: 1440px;
  margin: 0 auto;
}

.row {
  margin-left: -36px;
  margin-right: -36px;
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex: 1 0 0%;
  padding: 0 12px;
}

@for $i from 1 through 12 {
  .col-sm-#{$i} {
    width: $minColWidth * $i;
    flex: 0 0 auto;
  }
}

@media (max-width: $mobileMaxWidth) {
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      width: $minColWidth * $i;
      flex: 0 0 auto;
    }
  }

  .row {
    margin-left: -32px;
    margin-right: -32px;
  }

  .col {
    padding: 0 8px;
  }
}

@media (min-width: $tabletPortraitMinWidth) {
  @for $i from 1 through 12 {
    .col-md-#{$i} {
      width: $minColWidth * $i;
      flex: 0 0 auto;
    }
  }
}

@media (min-width: $tabletLandscapeMinWidth) {
  @for $i from 1 through 12 {
    .col-#{$i} {
      width: $minColWidth * $i;
      flex: 0 0 auto;
    }
  }
}

@media (min-width: $desktopMinWidth) {
  @for $i from 1 through 12 {
    .col-xl-#{$i} {
      width: $minColWidth * $i;
      flex: 0 0 auto;
    }
  }
}
