$mainFont: "SamsungOne", sans-serif;
$sharpSansFont: "SamsungSharpSans", "SamsungOne", sans-serif;
$SamsungOneArabic: "SamsungOneArabic", sans-serif;
$SamsungOneHebrew: "SamsungOneHebrew", sans-serif;
$SamsungOneKorean: "SamsungOneKorean", sans-serif;
$Padauk: "Padauk", sans-serif;
$SamsungOneThai: "SamsungOneThai", sans-serif;
$SamsungOneLatin: "SamsungOneLatin", sans-serif;

$mobileUnsupportedWidth: 359px;
$appMaxWidth: 1440px;
$desktopMinWidth: 1281px;
$tabletLandscapeMinWidth: 1024px;
$tabletPortraitMinWidth: 768px;
$tabletiPadAirWidth: 820px;
$mobileMinWidth: 320px;
$webSmallScreenMaxWidth: 1443px;
$webSmallScreenMinWidth: 768px;
$mobileMinWidth: 360px;

$tabletLandscapeMaxWidth: 1280px;
$tabletPortraitMaxWidth: 1023px;
$mobileMaxWidth: 767px;

// PRIMARY COLORS
$blk: var(--black);
$white: #ffffff;
$point: #006bea;

// POINT VARIATIONS
$point50: #eaf4ff;
$point100: #d0e6ff;
$point200: #b7d8ff;
$point300: #84bcff;
$point400: #51a1ff;
$point500: #2189ff;
$point600: #0477ff;
$point700: #006bea;
$point800: #005fd0;
$point900: #0054b7;
$point700Bea: #006bea;

// GRAYSCALE
$grayF7: #f7f7f7;
$grayEe: #eeeeee;
$grayDd: #dddddd;
$grayBb: #bbbbbb;
$grayAa: #aaaaaa;
$gray8f: #8f8f8f;
$gray75: #757575;
$gray55: #555555;
$gray31: #313131;
$gray1c: #1c1c1c;
$gray3c: #3c3c3c;
$gray4a: #4a4a4a;

// ETC

$line: var(--line);
$drawerLine: var(--drawerLine);
$dim: rgb(0 0 0 / 60%);
$negative: #f13c61;
$spacer: #5579f6;
$borderfocus: #2189ff;

// SECONDARY COLORS
$purple50: #f7f2fc;
$purple100: #f0e6fa;
$purple200: #e8d7fa;
$purple300: #e0c5fa;
$purple400: #d4acfc;
$purple500: #c78fff;
$purple600: #ba75ff;
$purple700: #aa5afa;
$purple800: #8d4ecc;
$purple900: #6a37a3;

$green50: #f7fcfb;
$green100: #edfcf8;
$green200: #e3faf3;
$green300: #d3f5ea;
$green400: #b2ebd9;
$green500: #a1e6d0;
$green600: #86d9bf;
$green700: #73c7ac;
$green800: #57a78e;
$green900: #49806e;

$pink50: #fcf7fb;
$pink100: #faebf6;
$pink200: #fccaf1;
$pink300: #f5b0e5;
$pink400: #f297dd;
$pink500: #eb8ad4;
$pink600: #e07bc9;
$pink700: #d667bc;
$pink800: #b34f9b;
$pink900: #8f3179;

$blue50: #334791;
$blue60: #2c4475;
$blue70: #130539;

$transparent: transparent;
$transparentRGBA: rgba(0, 0, 0, 0);
// Z Index
$overlayZi: 3;

// Icons
$iconCheckboxSelected: "/assets/images/svg/icon_checkbox_selected.svg";
$iconCheckboxDisabled: "/assets/images/svg/icon_checkbox_disabled.svg";
$iconCheckboxOff: "/assets/images/svg/icon_checkbox_off.svg";
$iconCheckboxCheck: "/assets/images/svg/icon_checkbox_check.svg";
$iconCheckboxHover: "/assets/images/svg/icon_checkbox_hover.svg";
$iconChevronRight: "/assets/images/svg/icon_chevron_right.svg";
$mapSmallBackground: "/assets/images/svg/map_small.svg";

$iconCheckboxSelectedHc: "/assets/images/svg/icon_checkbox_selected_hc.svg";
$iconCheckboxCheckHc: "/assets/images/svg/icon_checkbox_check_hc.svg";
$iconCheckboxOffHc: "/assets/images/svg/icon_checkbox_off_hc.svg";
$iconCheckboxHoverHc: "/assets/images/svg/icon_checkbox_hover_hc.svg";
$iconCheckboxDisabledHc: "/assets/images/svg/icon_checkbox_disabled_hc.svg";
$iconChevronRightHc: "/assets/images/svg/icon_chevron_right_hc.svg";

$hubManageBgMap: "/assets/images/svg/ic_bg_map.svg";
$hubManageBgMapHc: "/assets/images/svg/ic_bg_map_hc.svg";
$hubSeeDataBg: "/assets/images/png/ic_img_seedata.png";
$hubPrivacyBg: "/assets/images/png/ic_img_privacy.png";
$carouselDownloadBg: "/assets/images/webp/ic_banner_download.webp";
$carouselDeleteBg: "/assets/images/webp/ic_banner_delete.webp";
$carouselDeleteBgHc: "/assets/images/webp/ic_banner_delete_hc.webp";
$carouselOptoutBg: "/assets/images/webp/ic_banner_optout.webp";

$background1: var(--background1);
$linkTextColor: var(--linkTextColor);
$linkTextColor2: var(--linkTextColor2);
$linkTextColor3: var(--linkTextColor3);
$linkTextColor4: var(--linkTextColor4);
$linkTextColor5: var(--linkTextColor5);
$linkTextColor6: var(--linkTextColor6);
$textColor: var(--textColor);
$grayTextColor: var(--grayTextColor);
$whiteTextColor: var(--whiteTextColor);
$buttonTextColor: var(--buttonTextColor);
$backgroundOne: var(--background1);
$activeMenuTextColor: var(--activeMenuTextColor);
$secondaryLinkTextColor: var(--secondaryLinkTextColor);
$defaultBackground: var(--defaultBg);
$buttonDisabledBackground: var(--buttonDisabledBg);
$buttonDisabledTextColor: var(--buttonDisabledText);
$tab1depthTextColor: var(--tab1depthTextColor);
$tab1depthSelectedTextColor: var(--tab1depthSelectedTextColor);
$tooltipBackground: var(--tooltipBackground);
$background2: var(--background2);
$background3: var(--background3);
$background4: var(--background4);
$background5: var(--background5);
$background6: var(--background6);
$background7: var(--background7);
$checkboxSelected: var(--checkboxSelected);
$chevronMore: var(--chevronMore);
$lineBlue: var(--lineBlue);
$borderSwitch: var(--borderSwitch);
$switchOnBg: var(--switchOnBg);
$switchOnThumbBg: var(--switchOnThumbBg);
$shadow: var(--shadow);
$arrowColor: var(--arrowColor);
$toastBackground: var(--toastBackground);
$subTextColor: var(--subTextColor);
$timestampColor: var(--timestampColor);
$bullet: var(--bullet);
$toastCloseIconBackground: var(--toastCloseIconBackground);
$toastCloseIconText: var(--toastCloseIconText);
$euCloseText: var(--euCloseText);
$helpTipsOutline: var(--helpTipsOutline);
$gpsMapBackground: var(--gpsMapBackground);
$gpsCountryIcon: var(--gpsCountryIcon);
$gpsCountryName: var(--gpsCountryName);
$gpsSelectedRegionBackground: var(--gpsSelectedRegionBackground);
$gpsCountryModalLine: var(--gpsCountryLine);
$carouselDotActive: var(--carouselDotActive);
$carouselDot: var(--carouselDot);
$localSiteHeaderBackground: var(--localSiteHeaderBackground);
$consignmentTable: var(--consignmentTable);
