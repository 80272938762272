@import "src/assets/styles/customs/variables";

.login-modal {
  height: fit-content !important;
  @media (max-width: $mobileUnsupportedWidth) {
    width: 360px !important;
    margin: 0 !important;
  }
  .typography.title2 {
    word-wrap: break-word;
  }
  @media (min-width: $tabletPortraitMinWidth) {
    max-height: 90dvh !important;
    max-width: none !important;
    width: 40%;
  }
  @media (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
    max-height: 600px !important;
    height: calc(100vh - 100px) !important;

    .typography.title2 {
      font-size: 24px !important;
    }
    .modal-header {
      align-items: start !important;
    }
    .body2 {
      font-size: 16px;
    }
  }
  @media (max-width: $mobileUnsupportedWidth) {
    height: calc(100vh - 100px) !important;
    max-height: 600px !important;

    .typography.title2 {
      font-size: 24px !important;
    }
    .modal-header {
      align-items: start !important;
    }
    .body2 {
      font-size: 16px;
    }
  }
  .body2 {
    font-weight: 500;
  }
  .row {
    margin: 0;

    .col {
      text-align: center;
      &:first-child {
        padding-bottom: 20px;
        border-bottom: 1px solid $grayBb;

        @media (min-width: $tabletPortraitMinWidth) {
          padding-bottom: 0;
          border-bottom: none;
          border-right: 1px solid $grayBb;
          [dir="rtl"] & {
            border-right: none;
            border-left: 1px solid $grayBb;
          }
        }
      }

      &:last-child {
        padding-top: 20px;

        @media (min-width: $tabletPortraitMinWidth) {
          padding-top: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }
      }

      .typography.title2 {
        margin-bottom: 20px;
      }

      // @media (max-width: 280px) {
      //   .typography.title2 {
      //     font-size: 15px;
      //     margin-bottom: 5px;
      //   }

      //   .body2 {
      //     font-size: 14px;
      //   }
      //   .button {
      //     font-size: 12px;
      //     padding: 0.5rem;
      //   }
      // }

      .mt20 {
        margin-top: 20px;
      }

      .foccusable-div:has(:focus-visible) {
        outline: 2px solid $point700;
      }
    }
  }

  &__footer {
    display: flex;
    gap: 4px;
    flex-direction: column;
    width: 100%;

    .button {
      flex: 1 0 auto;
    }

    @media (min-width: $tabletPortraitMinWidth) {
      flex-direction: row;

      .button {
        width: 254px;
      }
    }
  }
}
