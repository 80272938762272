@import "src/assets/styles/customs/variables";
.accordion {
  .accordion-item {
    &:last-child {
      & .accordion-item__title {
        border-bottom: 1px solid $line;
      }
    }
    &__title {
      padding: 17px 8px 19px;
      cursor: pointer;
      border-top: 1px solid $line;
      font-family: $sharpSansFont;
      display: flex;
      align-items: center;

      width: 100%;
      justify-content: space-between;
      background-color: transparent;
      border: none;
      &:focus-visible {
        outline: 2px solid $point700;
      }

      &__text {
        // flex: 1;
        font-family: $sharpSansFont;
        color: $linkTextColor;
      }

      & .svg {
        color: $chevronMore;
      }
    }

    &__content {
      padding: 30px 16px;
      background-color: $background2;
      display: none;
    }

    &.active {
      & .accordion-item {
        &__title {
          & .svg {
            transform: rotate(181deg);
          }
        }

        &__content {
          display: block;
        }
      }
    }
  }
}
