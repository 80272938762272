@import "src/assets/styles/customs/variables";

.country-carousel {
  background:
    url(#{$hubManageBgMap}) no-repeat,
    $blue60;
  background-position-x: center;
  background-size: cover;
  border-radius: 26px;
  padding: 54px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  [data-theme="hc"] & {
    background: url(#{$hubManageBgMapHc}), $gray31;
    background-position-x: center;
  }
  @media (min-width: $mobileMinWidth) and (max-width: $tabletPortraitMaxWidth) {
    padding: 36px 8px;
  }
  @media (min-width: $tabletPortraitMinWidth) and (max-width: $tabletLandscapeMinWidth) {
    padding: 56px 42px;
  }

  &__content {
    height: 100%;
    text-align: center;

    & .typography {
      color: $whiteTextColor;
    }

    // Flag
    & > img {
      height: auto;
    }

    // Map icon
    & > .svg {
      margin: 0 auto;
      width: 74px;
      height: 60px;

      & + .country-carousel__content__text {
        margin: 30px 0;
      }
    }

    &__country {
      margin-top: 16px;
      line-height: 1.25;
      font-weight: 500;

      & > span {
        font-weight: bold;
      }

      @media (max-width: $mobileMaxWidth) {
        font-size: 14px;
      }
    }

    &__text {
      margin: 16px 0;
      line-height: 1.25;
      font-weight: 500;
      @media (max-width: $mobileMaxWidth) {
        font-size: 14px;
      }
    }

    &__button {
      border: none;
      color: $linkTextColor;
      border-radius: 26px;
      padding: 10px 16px 10px 24px;
      span {
        font-size: 16px;
        line-height: 1.25;
        font-weight: 500;
      }

      &:disabled {
        cursor: default;
      }

      & svg {
        fill: none !important;
      }

      [data-theme="hc"] & {
        background-color: $gray31;
        border: 1px solid $linkTextColor;
      }
    }
  }

  &__controls {
    &__button {
      margin: 0 29px;
      cursor: pointer;
      background-color: gray;
      border-radius: 100%;
      opacity: 0.4;

      @media (max-width: $mobileMaxWidth) {
        margin: 5px;
      }

      & path {
        stroke: $linkTextColor4;
      }
    }
  }

  &__dots {
    top: -15px !important;
    gap: 20px;

    & .paging-item {
      background-color: $carouselDot;
      border-radius: 26px;
      height: 16px;
      width: 16px;

      &.active {
        background-color: $carouselDotActive;
      }
    }

    &__dot {
      display: none;
    }
  }
}
