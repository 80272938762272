@import "src/assets/styles/customs/variables";

.manage-privacy {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  min-height: calc(100vh - 82px);
  background: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url("../../../../../assets/images/png/landingPage/banner.png") no-repeat;
  background-size: cover;
  background-position-x: center;
  text-align: center;
  display: flex;
  align-items: center;

  @media screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
    min-height: calc(100vh - 57px);
    background: linear-gradient(
        rgba(255, 255, 255, 0.75),
        rgba(255, 255, 255, 0.75)
      ),
      url("../../../../../assets/images/png/landingPage/banner-mobile.png")
        no-repeat;
    background-size: cover;
  }
  @media (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
    min-height: calc(100vh - 57px);
  }
  @media (min-width: $tabletLandscapeMinWidth) and (max-width: $tabletLandscapeMaxWidth) {
    min-height: calc(100vh - 57px);
  }

  [data-theme="hc"] & {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../../../../assets/images/png/landingPage/banner.png") no-repeat;
    background-position-x: center;
    background-size: cover;

    @media screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url("../../../../../assets/images/png/landingPage/banner-mobile.png")
          no-repeat;
      background-size: cover;
    }
  }
}
