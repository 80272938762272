@import "src/assets/styles/customs/variables";

.cookie-policy {
  padding-bottom: 200px;

  p {
    margin: 24px 0 40px;

    .link,
    a {
      color: $point700;
      font-weight: 500;
      cursor: pointer;

      [data-theme="hc"] & {
        color: $linkTextColor;
      }
    }
  }

  [data-theme="hc"] & {
    background-color: $gray31;
  }

  @media (max-width: $mobileMaxWidth) {
    padding-bottom: 80px;

    p {
      margin: 24px 0;
    }
  }

  &__title-section {
    padding-top: 80px;
    text-align: center;

    @media (max-width: $mobileMaxWidth) {
      padding-top: 40px;
    }

    &__button {
      text-align: center;
      margin: 24px 0 60px;

      [data-theme="hc"] & {
        span {
          color: $linkTextColor;
        }
      }

      @media (max-width: $mobileMaxWidth) {
        margin: 15px 0 42px;
      }
    }
  }

  &__cookie-table {
    border-top: 1px solid $blk;
    border-bottom: 1px solid $blk;
    margin-bottom: 40px;

    &__title {
      border-top: 1px solid $line;
      border-bottom: 1px solid $line;
      padding: 24px 0;

      & > .typography {
        padding: 0 16px;
      }
    }

    &__content {
      padding: 39px 40px 40px;

      @media (max-width: $mobileMaxWidth) {
        padding: 24px;
      }

      &__cookies {
        margin-top: 32px;
        border: 1px solid $line;
        border-radius: 20px;
        padding: 40px;

        @media (max-width: $mobileMaxWidth) {
          padding: 24px;
        }

        &__col {
          min-height: 250px;
          margin-bottom: 32px;
          border-bottom: 1px solid $line;
          max-width: 320px;

          @media (max-width: $mobileMaxWidth) {
            max-width: 100%;
            min-height: auto;
            padding-bottom: 16px;
            margin-bottom: 16px;
          }

          @media (min-width: $desktopMinWidth) {
            &.analytical {
              border-bottom: none;
              min-height: auto;
            }
          }
        }

        .col:nth-child(9),
        .col:last-child {
          .cookie-policy__cookie-table__content__cookies__col {
            &.essential {
              @media (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
                border-bottom: none;
                min-height: auto;
                margin-bottom: 0;
              }
            }
          }
        }

        .col:last-child {
          .cookie-policy__cookie-table__content__cookies__col {
            border-bottom: none;
            min-height: auto;
            margin-bottom: 0;

            @media (max-width: $mobileMaxWidth) {
              padding-bottom: 0;
            }
          }
        }

        &__label {
          margin-top: 16px;
          margin-bottom: 8px;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }

  hr {
    margin: 0;
  }
}
