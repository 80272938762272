@import "src/assets/styles/customs/variables";

.consignment-footer {
  display: flex;
  position: absolute !important;
  bottom: 0 !important;
  padding: 0 !important;
  height: 90px;
  align-items: center;
  justify-content: center;

  @media (max-width: $mobileUnsupportedWidth) {
    position: relative !important;
  }
}
.footer-mobile {
  position: relative;
  background-color: $defaultBackground;
  width: 100%;
  padding: 32px 0;
  border-top: 1px solid $line;
  height: auto;

  &.snap-scroll {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }

  &__logo {
    text-align: center;

    .svg {
      margin: 0 auto;
    }
  }

  &__content {
    text-align: center;

    &__link {
      margin-top: 12px;

      &:first-child {
        margin-top: 20px;
      }
    }

    &__language {
      display: flex;
      align-items: center;
      justify-content: center;

      &__link {
        display: flex;
        align-items: center;
        color: $linkTextColor;

        &.max-width {
          max-width: 185px;
        }
        .country {
          font-family: $sharpSansFont;
          display: flex;
          color: $linkTextColor;
        }

        .arrow-right {
          margin-left: 4px;
          display: flex;

          svg path {
            color: $linkTextColor;
          }

          [dir="rtl"] & {
            margin-left: 0;
            margin-right: 4px;

            svg {
              transform: rotate(180deg);
            }

            & > span {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    &__copyright {
      max-width: 255px;
      margin: 12px auto;
      text-align: center;
      opacity: 0.4;
      font-size: 12px;
      color: $textColor;

      [data-theme="hc"] & {
        opacity: 1;
      }
    }

    &__themes {
      display: flex;
      justify-content: center;

      & > span {
        cursor: pointer;
        margin: 0 4px;
      }
    }

    &__version {
      display: flex;
      margin-top: 1rem;
      flex-direction: column;

      p {
        margin: 0 10px;
      }
    }
  }
}
