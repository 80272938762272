@import "src/assets/styles/customs/variables";

.mydata {
  &.child-account {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title-section {
    margin-top: 60px;
    text-align: center;

    &__sub {
      margin-top: 12px;
      color: $subTextColor;
      margin-bottom: 24px;
    }
  }

  &__loading {
    display: block;
    margin: 50px auto;
  }

  &__china {
    margin: 60px auto 80px;

    &__icon {
      margin: 0 auto;
    }

    &__title {
      margin: 32px auto 16px;
      text-align: center;
    }

    &__altText {
      margin-top: 16px;
      text-align: center;

      a {
        color: $point700;
        text-decoration: underline;

        [data-theme="hc"] & {
          color: $linkTextColor;
        }
      }
    }

    &__text {
      text-align: center;
      font-size: 20px;
      max-width: 539px;
      margin: 0 auto 8px;

      &__bulleted {
        text-align: left;
        max-width: 539px;
        margin: 0 auto;
        font-size: 20px;

        a {
          color: $point700;
          text-decoration: underline;

          [data-theme="hc"] & {
            color: $linkTextColor;
          }
        }
      }
    }
  }

  @media (max-width: $mobileMaxWidth) {
    &__title-section {
      margin-top: 40px;

      & .h2 {
        line-height: 1.31;
        font-size: 26px;
      }

      &__sub {
        max-width: 222px;
        margin: 8px auto 16px;
        font-size: 16px;
        font-weight: normal;
      }

      &__more {
        font-size: 15px !important;
      }
    }

    &__china {
      margin: 33px auto 80px;
      &__icon {
        width: 180px !important;
        height: 180px !important;
        min-width: 180px !important;
        min-height: 180px !important;
      }

      &__title {
        margin: 24px auto 12px;
        font-size: 18px;
      }
    }
  }
}
