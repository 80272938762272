@import "src/assets/styles/customs/variables";

.h1 {
  font-size: 56px;
  font-weight: bold;
  line-height: 1.2;

  [lang="my"] & {
    line-height: 1.6;
  }
}

.h2 {
  font-size: 44px;
  font-weight: bold;
  line-height: 1.2;

  [lang="my"] & {
    line-height: 1.6;
  }
}

.h3 {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.2;

  [lang="my"] & {
    line-height: 1.6;
  }
}

.title1 {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.33;

  [lang="my"] & {
    line-height: 1.6;
  }
}

.title2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;

  [lang="my"] & {
    line-height: 1.6;
  }
}

.title3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.33;

  [lang="my"] & {
    line-height: 1.9;
  }
}

.body1 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;

  [lang="my"] & {
    line-height: 1.9;
  }
}

.body2 {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.33;

  [lang="my"] & {
    line-height: 1.9;
  }
}

.caption,
.body3 {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.33;

  [lang="my"] & {
    line-height: 1.9;
  }
}

.flag {
  font-size: 12px;
  line-height: 1.33;
  font-weight: 700;

  [lang="my"] & {
    line-height: 1.9;
  }
}

@media (max-width: $mobileMaxWidth) {
  .h1 {
    font-size: 28px;
    line-height: 1.33;

    [lang="my"] & {
      line-height: 1.9;
    }
  }

  .h2 {
    font-size: 26px;
    line-height: 1.33;

    [lang="my"] & {
      line-height: 1.9;
    }
  }

  .h3 {
    font-size: 22px;
    line-height: 1.33;

    [lang="my"] & {
      line-height: 1.9;
    }
  }

  .title1 {
    font-size: 20px;
  }

  .title2 {
    font-size: 18px;
  }

  .title3 {
    font-size: 16px;
  }

  .body1 {
    font-size: 16px;
  }

  .body2 {
    font-size: 15px;
  }

  .body3 {
    font-size: 14px;
  }

  .caption {
    font-size: 12px;
  }

  .flag {
    font-size: 12px;
    line-height: 1.33;
    font-weight: 700;

    [lang="my"] & {
      line-height: 1.9;
    }
  }
}

.w700 {
  font-weight: 700;
}

.w500 {
  font-weight: 500;
}

.w400 {
  font-weight: 400;
}

.wMedium {
  font-weight: normal;
}

.wBold {
  font-weight: bold;
}

.typography--ss-font {
  font-family: $sharpSansFont;
}
