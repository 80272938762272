@import "src/assets/styles/customs/variables";

.tts-button-container {
  display: inline-flex;
  vertical-align: middle;
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  margin-left: 8px;

  [dir="rtl"] & {
    margin-left: 0;
    margin-right: 8px;
  }

  &:focus-visible {
    outline: 2px solid $point700;
  }
}

.tts-button {
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;

  & path {
    fill: $linkTextColor !important;
  }

  & rect {
    fill: $linkTextColor !important;
  }

  @media (max-width: $mobileMaxWidth) {
    width: 20px !important;
    height: 20px !important;
  }
}
