@import "src/assets/styles/customs/variables";

.drawer-wrapper {
  overflow-x: auto;
}
.menu-drawer {
  left: -100vw;
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: $defaultBackground;
  visibility: hidden;
  transition: left 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
  z-index: 99;
  overflow: scroll;
  padding-bottom: 32px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* chrome */
  &::-webkit-scrollbar {
    display: none;
  }
  .drawer__profile {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    align-items: center;

    .name-and-icon-wrapper {
      display: flex;
      align-items: center;
      width: 80%;
      line-break: normal;
    }

    .sa-name-wrapper {
      width: 100%;
    }
  }

  .menu-drawer__profile__text__container {
    width: 60%;
    line-break: normal;
  }

  .drawer__profile__text__container {
    display: inline-block;
  }

  .block-event {
    pointer-events: none;
  }

  @media screen and (min-width: $mobileMinWidth) and (max-width: $tabletPortraitMaxWidth) {
    padding-bottom: 72px;
  }
  @media (max-width: $mobileUnsupportedWidth) {
    width: 360px;
    position: absolute;
  }
  [dir="rtl"] & {
    transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
    left: initial;
    right: -100vw;
  }

  .profile-placeholder svg {
    fill: $grayTextColor;
    color: $grayTextColor;
  }

  &.open {
    display: block;
    left: 0;
    visibility: visible;

    [dir="rtl"] & {
      left: initial;
      right: 0;
    }
  }

  &__close {
    padding: 16px;
    display: flex;
    justify-content: right;

    [dir="rtl"] & {
      justify-content: left;
    }

    &__btn {
      cursor: pointer;
      display: flex;
      color: $linkTextColor;
    }
  }

  &__profile {
    display: flex;
    align-items: center;
    margin-top: 16px;

    &__icon {
      margin-right: 16px;
      display: flex;
      cursor: pointer;

      img {
        border-radius: 50%;
      }

      [data-theme="hc"] & {
        svg {
          & > path {
            fill: $linkTextColor;
          }
          & path:nth-child(2) {
            fill: currentColor;
          }
        }
      }

      [dir="rtl"] & {
        margin-right: 0;
        margin-left: 16px;
      }
    }

    &__text__container {
      display: flex;
      flex: 1;
    }

    &__text {
      font-size: 16px;
      cursor: pointer;
      line-height: 1.25;
    }
  }

  &__message_container {
    margin: 24px 0 69px;

    &.login {
      margin-bottom: 12px;
    }
  }

  &__message {
    color: $gray31;
    font-size: 15px;
    line-height: 1.33;

    [data-theme="hc"] & {
      color: $textColor;
    }
  }

  &__signout-button {
    font-size: 14px;
    color: $linkTextColor;
    line-height: 1.43;
    cursor: pointer;
  }

  &__email {
    margin-bottom: 40px;

    [dir="rtl"] & {
      svg {
        transform: rotate(180deg);
      }
    }

    &__text {
      font-size: 14px !important;
      color: $linkTextColor !important;
      line-height: 1.5;
      line-break: anywhere;
    }
  }

  &__navs {
    & ul {
      list-style: none;
      margin: 0;
      padding: 0;

      & > li {
        position: relative;
        margin-top: 40px;

        & > a.active {
          font-weight: bold;
          border-bottom: 2px solid;
        }

        .menu-item-tooltip {
          position: absolute;
          right: 0;
          left: 40px;
          top: 32px;
          z-index: 1;
          color: $gray31;

          [dir="rtl"] & {
            left: 0;
            right: 40px;
          }

          .tooltip-title {
            font-weight: bold;
            color: $subTextColor;

            &:nth-child(4) {
              margin-top: 16px;
            }
          }

          .tooltip-sub-title {
            color: $subTextColor;
          }
        }

        .nav-item {
          display: flex;
        }

        & .nav-item > div {
          display: flex;
        }

        .item-text {
          font-size: 18px;
          margin-left: 16px;
          font-weight: 700;

          [dir="rtl"] & {
            margin-left: 0;
            margin-right: 16px;
          }

          .title2 {
            color: $linkTextColor;
          }
        }

        .item-tooltip {
          margin-left: 4px;
          cursor: pointer;
          opacity: 0.3;
          color: $linkTextColor;

          [data-theme="hc"] & {
            opacity: 1;
          }

          [dir="rtl"] & {
            margin-left: 0;
            margin-right: 4px;
          }
        }

        .sub-items {
          margin-left: 40px;

          [dir="rtl"] & {
            margin-left: 0;
            margin-right: 40px;
          }

          .sub-item-link {
            margin-top: 16px;
            font-size: 16px;
            font-weight: 400;
            .title3 {
              color: $linkTextColor;
            }
          }
          .sub-item-location {
            margin-top: 5px;
            display: flex;
            align-items: center;
            .title3 {
              color: $linkTextColor;
            }
            .countryName {
              padding-right: 6px;
              line-height: 1.55 !important;
            }
            [dir="rtl"] & {
              svg {
                transform: scaleX(-1);
              }
              .countryName {
                padding-left: 6px;
              }
            }
          }
        }
      }
    }
  }
}

.horizontal-divider {
  width: 100%;
  height: 1px;
  background-color: $drawerLine;
}

.modal-wrapper .overlay,
.modal-wrapper .modal-content {
  z-index: 5;
}
