@import "src/assets/styles/customs/variables";

.landingPage-top-banner {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../../../../assets/images/png/landingPage/banner.png")
    no-repeat;
  background-size: cover;
  background-position-x: center;
  min-height: calc(100vh - 82px);

  &__textContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    &__mouseScroll {
      position: absolute;
      bottom: 1%;
      left: 50%;

      @media (min-width: $mobileMinWidth) and (max-width: $tabletLandscapeMaxWidth) {
        display: none;
      }
    }
  }

  &__title__text {
    font-size: 64px;
    line-height: 1.25;
    font-family: $sharpSansFont;
    color: $linkTextColor5;
    margin: 12px 0;
  }

  &__caption__text {
    color: $linkTextColor5;
    line-height: 1.25;
    font-size: 24px;
    font-family: $mainFont;
    font-weight: 100;
  }

  @media (max-width: $mobileUnsupportedWidth) {
    &__title__text {
      font-size: 24px;
    }
  }
  @media screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
    min-height: calc(100vh - 57px);
    background: url("../../../../../assets/images/png/landingPage/banner-mobile.png")
      no-repeat;
    background-size: cover;
    background-position: center;
    .container {
      padding: 0 18px;
    }

    &__title__text {
      font-size: 24px;
      color: $linkTextColor5;
    }

    &__caption__text {
      line-height: 30px;
      font-size: 16px;
    }
  }

  @media screen and (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
    align-items: unset;
    min-height: calc(100vh - 56px);
    background-position-x: 60%;

    .container {
      padding: 0 18px;
    }

    &__title__text {
      font-size: 48px;
    }

    &__caption__text {
      font-size: 20px;
      line-height: 1.33;
    }
  }

  @media screen and (min-width: $tabletLandscapeMinWidth) and (max-width: $tabletLandscapeMaxWidth) {
    align-items: unset;
    min-height: calc(100vh - 56px);

    &__title__text {
      font-size: 64px;
    }

    &__caption__text {
      font-size: 24px;
      line-height: 1.33;
    }
  }

  [data-theme="hc"] & {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../../../../../assets/images/png/landingPage/banner.png") no-repeat;
    background-size: cover;
    background-position-x: center;

    @media screen and (min-width: $mobileMinWidth) and (max-width: $mobileMaxWidth) {
      min-height: calc(100vh - 57px);
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url("../../../../../assets/images/png/landingPage/banner-mobile.png")
          no-repeat;
      background-size: cover;
    }
    @media (max-width: $mobileUnsupportedWidth) {
      min-height: calc(100vh - 57px);
      background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
        url("../../../../../assets/images/png/landingPage/banner-mobile.png")
          no-repeat;
      background-size: cover;
    }
  }

  @media (max-width: $mobileUnsupportedWidth) {
    min-height: calc(100vh - 57px);
    background: url("../../../../../assets/images/png/landingPage/banner-mobile.png")
      no-repeat;
    background-size: cover;
    background-position: center;
    .container {
      padding: 0 18px;
    }

    &__title__text {
      font-size: 24px;
      color: $linkTextColor5;
    }

    &__caption__text {
      line-height: 30px;
      font-size: 16px;
    }
  }
}
