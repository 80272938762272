@import "src/assets/styles/customs/variables";

.input-holder {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.foccusable-div:has(:focus-visible) {
  outline: 2px solid $point700;
}

.text-danger {
  display: block;
  margin-bottom: 24px;
  color: $negative;
}

.text-center {
  text-align: center;
}
