@import "src/assets/styles/customs/variables";

.scroll-container {
  position: relative;
}

.scroll-wrapper {
  position: absolute;
  right: 80px;

  [dir="rtl"] & {
    left: 80px;
    right: initial;
  }
}

.scroll {
  bottom: 32px;
  position: fixed;
  display: none;

  @media (max-width: $mobileMaxWidth) {
    bottom: 16px;
  }

  &.open {
    display: block;
  }

  &.absolute {
    position: absolute;
  }

  &.services-selected {
    bottom: 180px;

    @media (max-width: $mobileMaxWidth) {
      bottom: 300px;
    }

    &--footer-reached {
      bottom: 52px;

      @media (max-width: $mobileMaxWidth) {
        bottom: 100px;
      }
    }
  }

  &__button {
    background: $defaultBackground;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 1px solid $line;
    transition: opacity 5s;

    //     Animation fade in
    animation: fadeIn ease 0.3s;
    -webkit-animation: fadeIn ease 0.3s;
    -moz-animation: fadeIn ease 0.3s;
    -o-animation: fadeIn ease 0.3s;
    -ms-animation: fadeIn ease 0.3s;
    @media (max-width: $mobileUnsupportedWidth) {
      display: none;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &:hover {
      cursor: pointer;
      // background-color: $gray1c;
    }

    &:focus-visible {
      outline: 2px solid $point700;
    }

    & .svg {
      margin: 0 auto;
    }

    > span svg {
      fill: $linkTextColor;
    }
  }
}
