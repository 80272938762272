@import "src/assets/styles/customs/variables";

ul {
  margin-block-start: 1em;
  padding-inline-start: 11px;
  margin-block-end: 0em;
}

.w-full {
  width: 100% !important;
}
.mw-none {
  max-width: none !important;
}
.confirmation-modal__ul .confirmation-modal__li {
  content: "•";
  font-size: 50%;
  margin-bottom: 8px;

  .body3 {
    color: $textColor;
  }

  &::marker {
    color: $textColor;
  }
}

.confirmation-modal__ul .confirmation-modal__li:last-child {
  margin-bottom: 0px;
}

.service-name {
  word-break: break-word;
  color: $gray31 !important;
  font-family: $sharpSansFont !important;
  color: $subTextColor !important;

  @media (max-width: $mobileMaxWidth) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.confirmation-modal {
  .loader-holder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal {
    @media (max-width: $mobileUnsupportedWidth) {
      width: 360px !important;
      margin: 0 !important;
    }
    & .modal-body {
      height: 100%;
      margin-top: 32px !important;
      margin-bottom: 32px !important;
      padding: 0 !important;
    }
  }

  &__prompt {
    text-align: center;
    padding: 16px;
    background-color: $background3;
    height: fit-content;
    width: 100%;
    border-radius: 12px;
    margin-bottom: 24px;

    [data-theme="hc"] & {
      border: 1px solid $grayF7;
    }
  }

  &__services-banner {
    text-align: center;
    background-color: $background1;
    border-radius: 12px;
    padding: 40px 32px 32px;

    &__blue {
      color: $point700;

      [data-theme="hc"] & {
        color: $linkTextColor;
      }
    }

    &__container-mini {
      margin: 24px 0px 0px;
      place-content: center;
    }
    &__container {
      margin: 24px 0px 0px;

      &__col {
        padding: 0px 4px 8px !important;
      }

      &__service {
        background-color: $defaultBackground;
        padding: 24px 20px;
        height: 100%;
        border-radius: 6px;

        &__icon {
          margin-bottom: 14px;
          border-radius: 100%;
        }
      }

      &__service-delete {
        background-color: $defaultBackground;
        padding: 24px;
        height: fit-content;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        text-align: left;
        border-radius: 6px;

        [dir="rtl"] & {
          text-align: right;
        }

        &__service-name {
          font-weight: 700;
          margin-bottom: 12px;
        }

        &__icon {
          margin-right: 24px;
          border-radius: 100%;

          [dir="rtl"] & {
            margin-right: 0;
            margin-left: 24px;
          }
        }

        a {
          color: $point700;
        }
      }
    }

    &__divider {
      width: 100%;
      display: flex;
      text-align: center;
      align-items: center;
      max-height: 40px;

      &__line {
        background-color: $lineBlue;
        width: 100%;
        display: flex;
        height: 1px;
      }

      &__badge {
        padding: 0px 8px;
        background-color: $background1;
        display: flex;

        &__icon {
          background-color: $lineBlue;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          &__symbol {
            color: $point700;

            [data-theme="hc"] & {
              color: $textColor;
            }
          }
        }
      }
    }
    &__account {
      margin-top: 16px;
      text-align: center;

      .body3 {
        color: $textColor;
      }

      &__email {
        color: $linkTextColor2;
        margin-top: 8px;

        [data-theme="hc"] & {
          color: $linkTextColor;
        }
      }
    }
  }

  &__info {
    margin-top: 32px;

    &__title {
      display: flex;
      width: 100%;
      height: fit-content;
      align-items: center;
      color: $textColor;

      &__info {
        font-weight: bold;
        margin-left: 8px;

        [dir="rtl"] & {
          margin-left: 0;
          margin-right: 8px;
        }
      }
    }

    &__title-divider {
      width: 100%;
      height: 12px;
      border-bottom: solid 1px $drawerLine;
    }
  }

  .button-container {
    display: flex;
    flex: 1 1;
    margin-right: 4px;

    &:last-child {
      margin-right: 0px;
    }

    &:has(:focus-visible) {
      outline: 2px solid $point700;
    }

    [dir="rtl"] & {
      margin-left: 4px;
      margin-right: 0px;

      &:last-child {
        margin-left: 0px;
      }
    }

    button {
      width: 100%;

      &:focus-visible {
        outline: none;
      }
    }
  }

  @media (max-width: $mobileMaxWidth) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    max-width: none !important;
    .body {
      overflow: hidden;
    }

    ::-webkit-scrollbar {
      width: 5px;
      height: 0px;
    }

    .modal-footer {
      padding: 0px 16px !important;
    }

    .modal-body {
      margin-left: -24px !important;
      margin-right: -24px !important;
      width: calc(100% + 48px) !important;
      height: auto !important;
      flex: 1;
      overflow-x: hidden;
    }
    .modal-content {
      width: 100% !important;
      height: 100% !important;
    }
    &__prompt {
      text-align: center;
      padding: 16px 24px;
      background-color: $background3;
      height: fit-content;
      width: 100%;
      border-radius: 0px;
      margin-bottom: 16px;
    }

    &__services-banner {
      text-align: center;
      background-color: $background1;
      border-radius: 0px;
      padding: 32px 24px 28px;

      &__container {
        margin: 16px 0px 0px;
        place-content: space-around;
        overflow: hidden;

        &__service {
          background-color: $defaultBackground;
          padding: 24px 12px;
          max-width: 98px;
          min-height: 162px;
          height: 162px;
          margin-bottom: 8px;
          margin-right: 8px;
          border-radius: 6px;

          [dir="rtl"] & {
            margin-right: 0;
            margin-left: 8px;
          }

          &__icon {
            margin-bottom: 14px;
          }
        }

        &__service:last-of-type {
          margin-right: 0px;

          [dir="rtl"] & {
            margin-left: 0;
          }
        }

        &__service-alt {
          width: 107%;
          display: block;
          background-color: transparent;
          margin-left: 0px;
          height: 162px;

          &__scroll {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            max-height: 162px;
            overflow-y: hidden;
            overflow-x: scroll;
            align-items: center;
          }
          &__scroll-mini {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            max-height: 172px;
            overflow-y: hidden;
            overflow-x: scroll;
            place-content: center;
          }
        }

        &__service-delete {
          background-color: $defaultBackground;
          padding: 24px;
          height: fit-content;
          margin-bottom: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 6px;

          &__service-name {
            text-align: center;
            font-weight: 700;
            margin-bottom: 14px;
          }

          &__icon {
            margin: 0px 0px 12px;
            border-radius: 100%;
          }
        }
      }
      &__divider {
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        max-height: 40px;
        margin-top: 24px;

        &__line {
          background-color: $point200;
          width: 100%;
          display: flex;
          height: 1px;
        }

        &__badge {
          padding: 0px 8px;
          background-color: $background1;
          display: flex;

          &__icon {
            background-color: $lineBlue;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            &__symbol {
              color: $point700;
            }
          }
        }
      }
    }

    &__info {
      margin: 32px 24px;

      &__title {
        display: flex;
        width: 100%;
        height: fit-content;
        align-items: center;

        &__info {
          margin-left: 8px;
        }
      }

      &__title-divider {
        width: 100%;
        height: 12px;
        border-bottom: solid 1px $drawerLine;
      }
    }
  }
}
