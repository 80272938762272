@import "src/assets/styles/customs/variables";
.version-history-modal {
  @media (max-width: $mobileMaxWidth) {
    .modal-content {
      position: fixed !important;
      top: initial !important;
      margin: 0 24px !important;
    }
  }
  .modal {
    max-width: 448px !important;
  }

  &__item {
    padding: 17px 8px 19px;
    border-top: 1px solid $line;
    cursor: pointer;

    &:last-child {
      border-bottom: 1px solid $line;
    }

    &.active {
      background-color: $background1;
    }
  }
}
