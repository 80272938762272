@import "src/assets/styles/customs/variables";

.privacy-carousel {
  border-radius: 26px;
  margin: 0 auto;

  &__details {
    line-height: 1.33;
  }

  &__controls {
    &__button {
      margin: 0 29px;
      cursor: pointer;

      @media (max-width: $mobileMaxWidth) {
        margin: 0;
      }
    }
  }

  &__dots {
    top: -25px !important;
    gap: 20px;

    & .paging-item {
      background-color: $carouselDot;
      border-radius: 26px;
      height: 16px;
      width: 16px;

      &.active {
        background-color: $carouselDotActive;
        border: 1px solid #bbb;
      }
    }

    &__dot {
      display: none;
    }
  }

  &__info {
    padding: 0 108px;
    height: 400px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $mobileMaxWidth) {
      padding: 15px 48px 50px 48px;
      height: auto;

      &__details {
        font-size: 14px;
      }

      &__title {
        font-size: 20px;
      }
    }

    & .typography {
      color: $linkTextColor5;
    }

    & .text-container {
      width: 80%;

      @media (max-width: $mobileMaxWidth) {
        width: 100% !important;
      }
    }

    &.download {
      background: url(#{$carouselDownloadBg});
      min-height: 100%;
      background-size: cover;

      & .text-container {
        width: 70%;
      }

      @media (max-width: $mobileMaxWidth) {
        padding-right: 135px;
        background-position-x: 70%;

        & .text-container {
          width: 100%;
        }
      }

      @media (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
        padding: 30px 90px 50px 90px;
        background-position-x: 70%;
      }
    }

    &.delete {
      background: url(#{$carouselDeleteBg});
      align-items: flex-end;
      text-align: right;
      height: 100%;
      background-size: cover;

      & .typography {
        color: $textColor;
      }

      & .text-container {
        width: 69%;
      }

      @media (max-width: $mobileMaxWidth) {
        padding-left: 135px;
        background-position-x: 23%;
      }

      @media (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
        padding: 30px 90px 50px 90px;
        background-position-x: 20%;
      }

      [data-theme="hc"] & {
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
          url(#{$carouselDeleteBgHc}) no-repeat;
        background-size: cover;
        background-position-x: 23%;
      }
    }

    &.opt-out {
      background: url(#{$carouselOptoutBg});
      height: 100%;
      background-size: cover;

      @media (min-width: $desktopMinWidth) {
        & .text-container {
          width: 50%;
        }
      }

      @media (max-width: $mobileMaxWidth) {
        padding-right: 135px;
        background-position-x: 40%;
      }

      @media (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
        padding: 30px 90px 50px 90px;
        background-position-x: 30%;
      }
    }

    &__title {
      margin-bottom: 16px;
    }
  }
}
