@import "src/assets/styles/customs/variables";

.alert-wrapper {
  display: none;

  #error-status {
    color: $negative !important;
  }
  &.open {
    display: block;

    @media (max-width: $mobileMaxWidth) {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 5;
    }
  }
  .overlay {
    z-index: 6;
  }

  .alert-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 7;
    height: 100vh;
    overflow-x: auto;

    .alert {
      max-width: 448px;
      margin: 0 auto;
      background-color: $defaultBackground;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 20px;
      padding: 24px;

      .alert-header {
        display: flex;
        align-items: center;

        &__title {
          margin-right: 12px;
          flex: 2;

          [dir="rtl"] & {
            margin-right: 0;
            margin-left: 12px;
          }
          @media (max-width: $mobileUnsupportedWidth) {
            margin-right: 0;
            flex: 2;
          }
        }
      }

      .alert-header__close__btn {
        display: flex;
        justify-content: right;
        cursor: pointer;
        margin-bottom: 4px;
        color: $linkTextColor;
        border: none;
        padding: 0;
        background-color: transparent;

        &:focus-visible {
          outline: 2px solid $point700;
        }
      }

      .alert-icon {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
      }

      .alert-text {
        font-size: 18px;
        text-align: center;
        line-height: 1.18;
      }

      .alert-sub-text {
        margin: 0 auto;
        text-align: center;
        margin-top: 12px;
        font-size: 16px;
        line-height: 1.33;
        font-weight: normal;
      }

      .alert-body {
        margin: 4px 0 32px;
      }

      .alert-footer {
        display: flex;
        margin-top: 32px;
      }
    }
  }

  @media (max-width: $mobileMaxWidth) {
    & .alert-content {
      top: -57px;
      padding: 0 24px;
    }

    button {
      padding-right: 16px;
    }

    .alert {
      margin: 0 auto;

      .alert-text {
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
@media (max-width: $mobileUnsupportedWidth) {
  .alert-content {
    top: -57px;
    padding: 0 24px;
    .alert {
      width: 300px !important;
      margin: 0 24px;
    }
  }
  .alert-content::-webkit-scrollbar {
    display: none !important;
  }
}

.button-container {
  display: flex;
  flex: 1 1;

  &:first-child {
    margin-right: 4px;
  }

  [dir="rtl"] & {
    &:first-child {
      margin-right: 0px;
      margin-left: 4px;
    }
  }

  .button {
    width: 100%;
  }
}
