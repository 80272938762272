@import "src/assets/styles/customs/variables";

.cookie-noneu {
  .alert-content {
    @media (max-width: $tabletPortraitMaxWidth) {
      top: 0 !important;
    }
  }

  .alert {
    max-width: 560px !important;

    @media (max-width: $mobileMaxWidth) {
      max-width: 312px;
    }
    @media (max-width: $mobileUnsupportedWidth) {
      max-width: 312px;
    }
  }
  .alert-header__title {
    font-family: $sharpSansFont;
  }
  .alert-header__close__btn {
    display: none !important;
  }

  .alert-footer:has(:focus-visible) {
    outline: 2px solid $point700;
  }

  &__text {
    margin: 35px 0 32px;
    padding: 24px 24px 32px;
    background-color: $background2;
    border-radius: 12px;

    &__link {
      font-weight: 700;
      color: $linkTextColor2;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__accept-button {
    width: 100%;
  }
}

.cookie-eu {
  .alert-content {
    @media (max-width: $mobileMaxWidth) {
      top: 0 !important;
    }
  }
  .alert {
    max-width: 560px !important;

    @media (max-width: $mobileMaxWidth) {
      max-width: 312px;
    }
    @media (max-width: $mobileUnsupportedWidth) {
      width: 312px !important;
    }
  }
  .alert-header__title {
    font-family: $sharpSansFont;
  }
  .alert-header__close__btn {
    display: none !important;
  }

  .alert-footer {
    .button-container:has(:focus-visible) {
      outline: 2px solid $point700;
    }

    @media (max-width: $mobileMaxWidth) {
      display: flex !important;
      margin-top: 24px !important;
      flex-direction: column-reverse;
      .button-container {
        padding: 0 !important;

        &:first-child {
          margin-top: 8px;
        }
      }
    }
  }

  &__close {
    align-self: baseline;
    display: flex;
    justify-content: right;
    flex: 1;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;

    &:focus-visible {
      outline: 2px solid $point700;
    }

    & .svg {
      margin-left: 4px;
      min-width: 12px;
      min-height: 12px;

      [dir="rtl"] & {
        margin-left: 0;
        margin-right: 4px;
      }
    }

    & .svg svg {
      fill: $grayAa;
    }

    &__text {
      color: $euCloseText;
      display: flex;
      align-items: center;
    }

    @media (max-width: $mobileMaxWidth) {
      display: none;
    }
  }
  &__close-in-body {
    align-self: baseline;
    display: flex;
    justify-content: right;
    flex: 1;
    cursor: pointer;
    margin-bottom: 32px;

    & .svg {
      margin-left: 4px;
      min-width: 12px;
      min-height: 12px;

      [dir="rtl"] & {
        margin-right: 4px;
        margin-left: 0;
      }
    }

    & .svg svg {
      fill: $grayAa;
    }

    &__text {
      color: $grayAa;
      display: flex;
      align-items: center;
    }

    @media (min-width: $tabletPortraitMinWidth) {
      display: none;
    }
  }

  &__text {
    margin: 35px 0 32px;
    padding: 24px 24px 32px;
    background-color: $background2;
    border-radius: 12px;

    @media (max-width: $mobileMaxWidth) {
      margin: 24px 0 12px;
    }

    &__link {
      color: $point;
      font-weight: 700;
      color: $point700;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  & .button-container .button {
    padding: 10px 16px;
  }
}

.manage-cookie {
  @media (max-width: $mobileMaxWidth) {
    max-width: 312px !important;

    .modal-wrapper {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 5;
    }

    .modal-content {
      // top: -57px !important;
    }
  }

  .modal {
    max-width: 640px !important;

    @media (max-width: $mobileMaxWidth) {
      margin: 0 24px !important;
    }
  }

  &__top-text {
    text-align: center;
    background-color: $background3;
    border-radius: 12px;
    padding: 16px 24px;
    margin-bottom: 24px;

    [data-theme="hc"] & {
      border: 1px solid $grayF7;
    }

    @media (max-width: $mobileMaxWidth) {
      border-radius: 0;
    }
  }

  @media (max-width: $mobileMaxWidth) {
    .modal-body {
      margin: 29px -24px !important;
      height: calc(100% - 180px) !important;
    }
  }

  &__content {
    padding: 32px 24px;
    background-color: $background1;
    border-radius: 12px;

    @media (max-width: $mobileMaxWidth) {
      border-radius: 0;
    }

    &__box {
      background-color: $defaultBackground;
      border-radius: 12px;
      padding: 24px;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      &__head {
        display: flex;

        & > .typography {
          flex: 1;
        }
      }

      &__description {
        margin-top: 16px;
      }

      &__hide {
        margin-top: 16px;
        text-align: right;
        display: flex;
        justify-content: right;

        & > button {
          padding: 0;
          border: none;
          background-color: transparent;

          &:focus-visible {
            outline: 2px solid $point700;
          }

          & > span {
            cursor: pointer;
            display: flex;
            align-items: center;
            color: $linkTextColor;

            .svg {
              margin-left: 4px;
              fill: $linkTextColor;
            }
          }
        }

        &.open {
          .svg {
            transform: rotate(180deg);
          }
        }
      }

      &__table {
        padding: 16px 0;
        margin-top: 16px;
        border-top: 1px solid $line;
        display: none;
        position: relative;

        &.open {
          display: block;
        }

        & .row:first-child {
          margin-bottom: 12px;
        }

        &__wSwitch {
          align-items: center;

          &__switch {
            display: flex;
            justify-content: right;

            @media (max-width: $mobileMaxWidth) {
              position: absolute;
              right: -8px;
              top: 16px;
            }
          }
        }
      }
    }
  }

  .modal-footer {
    .button-container {
      &:has(:focus-visible) {
        outline: 2px solid $point700;
      }

      &:first-child {
        padding-left: 0;
        padding-right: 4px;
      }

      &:last-child {
        padding-right: 0;
        padding-left: 4px;
      }
    }
  }

  @media (max-width: $mobileMaxWidth) {
    .modal-footer {
      display: block !important;
    }
    .button-container {
      padding: 0 !important;

      &:last-child {
        margin-top: 8px;
      }
    }
  }
}
